@import '../../../../../../assets/scss/variable.scss';

.left-profile-details-box{
    .profile-completion-sec{
        display: flex;
        padding-bottom: 25px;
        .profile-com-text{
            font-size: 21.33px;
            line-height: 26px;
            font-family: $authHeaderFont;
            color: $color-white;
            margin-left: 8px;
        }
        .MuiIconButton-root {
            margin-left: -9px;
        }
    }
    .progress-bar-sec{
        .title-text{
            font-size: 16px;
            line-height: 21px;
            font-weight: 400;
            font-family: $primaryFont;
        }
    }
    .profile-details-sec{
        margin-bottom: 57px;
        .profile-details{
            padding: 0;
            .MuiListItemAvatar-root{
                min-width: 25px;
                align-self: start;
                margin-top: 4px;
            }
            .MuiListItem-root{
               padding: 9px 0px;
            }
            .MuiListItemText-root{
                margin-top: 0;
                margin-bottom: 0;
            }
            .MuiListItemText-primary{
                font-size: 16px;
                line-height: 21px;
                font-weight: 400;
                font-family: $primaryFont;
                color: $color-white;
            }
            .MuiListItemText-secondary{
                font-size: 12px;
                line-height: 21px;
                font-weight: 400;
                font-family: $primaryFont;
                color: $color-spanish-gray;
            }
            .interaction-sec{
                padding-left: 30px;
                .interaction-numbers{
                    display: inline;
                    font-size: 16px;
                    line-height: 21px;
                    font-weight: 400;
                    font-family: $primaryFont;
                    color: $color-blue;
                }
            }
        }
    }
}
.linear-progress-bar{
    margin: 9px 0 18px;
    .MuiLinearProgress-root{
        height: 8px;
        background-color: $color-white;
        border-radius: 3px;
        border: 0;
        .MuiLinearProgress-bar {
            background-color: $color-blue;
        }
    }
}
