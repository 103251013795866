@import '../../../../../assets/scss/variable.scss';

.d-block {
  display: block !important;
}

.community-page-main {
  .bpdtr-box {
    margin-bottom: 0;
    .tab-title {
      display: flex;
      justify-content: space-between;
      font-size: 28.45px;
      line-height: 38px;
      font-family: $authHeaderFont;
      color: $color-white;
    }
    .alphabet-number {
      .MuiTab-root {
        padding: 0 6px;
        font-family: $primaryFont;
        font-size: 18px;
        line-height: 24px;
      }
    }
    @media (max-width: 600px) {
      .alphabet-number {
        .MuiTab-root {
          padding: 0 6px;
          min-width: 0;
        }
      }
    }
    .tabpanel-sec {
      .alphabet-text {
        font-family: $primaryFont;
        font-size: 16px;
        line-height: 21px;
        color: $color-white;
      }
      .comm-dir-title {
        font-family: $primaryFont;
        font-size: 16px;
        line-height: 21px;
        color: $color-white;
      }
    }
    .Mui-selected {
      background: transparent;
      color: $color-blue;
    }
  }
}
