@import '../../../../assets/scss/variable.scss';

.notification-screen {
  .no-notification-text {
    font-size: 30px;
    line-height: 38px;
    font-family: $authHeaderFont;
    color: $color-white;
  }
}
