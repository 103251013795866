@import '../../../../assets/scss/variable.scss';

.cd-dropdown-main {
  background: #202020;
  color: $color-white;
  padding: 15px;
  position: absolute;
  width: 100%;
  z-index: 11;
  height: 400px;
  overflow-y: auto;
  .search-field-box {
    background: #202020;
    padding: 0;
    border: $blockBorder;
  }
  .dropdown-listing-main {
    padding-top: 15px;
    .MuiListItem-gutters {
      padding: 8px 0;
      border-bottom: $blockBorder;
    }
  }
}
.btn-main {
  .dropdown-label {
    width: 100%;
    background: transparent;
    color: $color-white;
    font-size: 18px;
    text-transform: unset;
    font-weight: 300;
    border: $blockBorder;
    &:hover {
      background: transparent;
    }
  }
  .MuiButton-label {
    display: flex;
    justify-content: space-between;
  }
}
.position-relative {
  position: relative;
}
