@import '../../../../../../assets/scss/variable.scss';

.left-profile-container {
  position: relative;
  width: 100%;

  .cover-photo-container {
    .MuiButtonBase-root {
      width: 100%;
      padding: 0;
    }

    .preview-cover {
      position: relative;
      width: 100%;
      height: 308px;
      border-radius: $bRadius;

      @media (max-width: 599px) {
        height: 200px;
      }
    }
    .camera-icon {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    .upload-button {
      width: 100%;
      border-radius: $bRadius;
      padding: 146px 0px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      font-family: $primaryFont;
      text-transform: capitalize;
      background-color: #1b1b1b;
      border: $blockBorder;
      color: #9d9d9d;
    }

    .MuiButton-endIcon {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
}
