@import '../../../../../../../../assets/scss/variable.scss';

.endorement-snackbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 18px;
  background: $color-blue;
  border-radius: $bRadius;
  @media (min-width: 959px) {
    width: 506px;
  }

  p {
    font-size: 21.33px;
    line-height: 26px;
    font-family: $authHeaderFont;
    color: $color-white;
  }

  .MuiIconButton-root {
    width: 16px;
  }
}

.discipline-table-container {
  position: relative;
  display: flex;
  margin-bottom: 9px;

  .user-discipline {
    min-width: 586px;

    @media (max-width: 1279px) {
      min-width: 0;
    }
    @media (min-width: 1280px) {
      max-width: 586px;
    }
  }

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #231f20;
    border-radius: $bRadius;
  }

  ::-webkit-scrollbar-thumb {
    background: $color-blue;
    border-radius: $bRadius;
  }

  .MuiTableCell-sizeSmall {
    padding: 2px;
  }

  .MuiTableCell-root {
    border-bottom: none;
  }

  .MuiTable-root {
    min-width: 657px;
    margin-top: 9px;
    margin-bottom: 22px;

    @media (max-width: 599px) {
      min-width: 722px;
    }
  }

  .MuiTableCell-head {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    font-family: $primaryFont;
    color: #b4babf;

    .MuiIconButton-root {
      margin-left: 5px;
    }
  }

  .MuiTableBody-root {
    padding-top: 9px;
  }

  .MuiTableCell-body {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }

  .style-cell {
    // width: 146px;
    width: 250px;
  }

  .rank-cell {
    // width: 66px;
    width: 150px;
    margin-left: 5px;
  }

  .gym-cell {
    width: 200px;
    margin-left: 5px;
  }

  .date-generated-cell {
    width: 119px;
    margin-left: 5px;
  }
  .endorsement-cell {
    width: 121px;
    margin-left: 5px;
  }

  .add {
    margin-top: -2px;
  }

  .endorsement-cell {
    .MuiAvatar-root {
      width: 21px;
      height: 21px;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-blue;
      color: $color-white;
    }
  }

  .discipline-actions {
    background-color: $blockColor;
    position: absolute;
    right: -15px;
    .edit-delete {
      display: flex;
    }
    .MuiIconButton-root {
      margin: 0px 4px;

      &:first-child {
        margin-left: 11.5px;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: 599px) {
      position: relative;
    }
  }
}
