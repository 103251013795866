@import '../../../../../../assets/scss/variable.scss';

.messages-right-side-sec {
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  .back-icon {
    align-self: center;
    cursor: pointer;
  }

  .right-person-details {
    padding: 12px;
    display: flex;
    border-bottom: $blockBorder;
    cursor: pointer;
    .right-avatar-sec {
      width: 54px;
      height: 54px;
      margin-right: 12px;
    }
    .right-person-name{
      width: 100%;
      @media (max-width: 959px) {
        width: 90%;
      }
      @media (max-width: 375px) {
        width: 85%;
      }
    }
    .right-single-name{
      display: flex;
    }
    .right-name-sec {
      font-family: $primaryFont;
      font-size: 21.33px;
      line-height: 28px;
      color: $color-white;
      text-transform: capitalize;
      align-self: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
    }
    .group-members-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
      display: block;
    }
  }
  .image-wrapper{
    .image-wrapper-inner{
      .create-post-item-sec{
        margin: 10px 0;
      }
    }
  }
  .right-chat-sec {
    border-bottom: $blockBorder;
    padding: 9px 12px;
    max-height: 335px;
    min-height: 350px;
    box-sizing: border-box;
    overflow: scroll;
    .right-date-sec {
      font-family: $authHeaderFont;
      font-size: 18px;
      line-height: 22px;
      color: $color-white;
      text-align: center;
    }
    .loader-chat {
      display: flex;
      margin: 0 auto;
    }
  }
  .right-chat-sec::-webkit-scrollbar {
    display: none;
  }
  .right-send-sec {
    border-bottom: $blockBorder;
    // padding: 7px 12px;
    // display: flex;
    // justify-content: space-between;
    // .text-decoration-sec {
    //   display: flex;
    //   column-gap: 10px;
    //   svg {
    //     path {
    //       fill: $color-text-deco;
    //       stroke: $color-text-deco;
    //       stroke-width: 0;
    //     }
    //   }
    // }
    .send-icon-sec {
      background-color: $color-check;
      border-radius: 50%;
      // padding: 1px 5px;
      padding: 1px 4px 1px 6px;
    }
  }
  .custome-text-editior {
    .editior-error-message {
      font-family: $primaryFont;
      color: $color-pin;
    }
    .rdw-suggestion-dropdown {
      color: $color-black;
      min-width: 200px;
    }
    .rdw-mention-link {
      background: transparent;
      color: $color-blue;
    }
    .rdw-editor-toolbar {
      background: none;
      border: $blockBorder;
      position: relative;
      .rdw-option-wrapper {
        background: none;
        border: none;
      }
      @media (max-width: 375px) {
        .rdw-option-wrapper {
          padding: 2px;
          min-width: 15px;
        }
      }
      .rdw-option-wrapper:hover {
        box-shadow: none;
      }
      .rdw-option-active {
        box-shadow: none;
        border: $blockBorder;
      }
    }
    .rdw-editor-main {
      border: $blockBorder;
      .public-DraftStyleDefault-ltr {
        font-family: $primaryFont;
        margin: 12px 10px;
      }
      .public-DraftEditorPlaceholder-root {
        font-family: $primaryFont;
      }
      .public-DraftEditorPlaceholder-inner {
        margin: 0px 10px;
      }
    }
    .demo-editor {
      min-height: 180px !important;
    }
    .rdw-image-modal {
      background: $color-black;
      font-family: $primaryFont;
      border: $blockBorder;
      box-shadow: none;
      height: auto;
      .rdw-image-modal-url-input {
        margin-bottom: 5px;
        height: 28px;
      }
    }
    .rdw-link-modal {
      background: $color-black;
      font-family: $primaryFont;
      border: $blockBorder;
      box-shadow: none;
      height: auto;
      .rdw-link-modal-input {
        margin-bottom: 5px;
        height: 28px;
      }
    }
  }
  .post-image-uploader {
    .MuiButton-containedPrimary {
      padding: 0;
      background: transparent;
      &:hover {
        background: transparent;
      }
    }
    .MuiButton-label {
      display: inline-block;
    }
    .MuiButton-root {
      min-width: auto;
    }
  }
  .post-image-uploader.send-image-sec {
    position: absolute;
    right: 12px;
    background-color: $color-check;
    border-radius: 50%;
    padding: 2px 7px 0px 10px;
    .loader-chat {
      width: 20px !important;
      height: 20px !important;
    }
  }
  .loader-padding {
    padding: 2px 5px 0px !important;
  }
  .write-something-sec {
    padding: 8px 15px;
    height: 125px;
    box-sizing: border-box;
    .write-something-text {
      font-family: $primaryFont;
      font-size: 18px;
      line-height: 24px;
      color: $color-pearl;
    }
  }
  .messages-reply-box {
    display: flex;
    padding: 9px;
    background-color: $color-light-black;

    .reply-message-sec {
      width: 90%;
      border: $blockBorder;
      border-left: 5px solid $color-check;
      border-radius: 5px;
      background-color: $blockColor;
      padding: 10px;
      word-break: break-word;
    }
    .messages-user-name {
      font-family: $primaryFont;
      font-size: 16px;
      line-height: 21px;
      color: $color-check;
    }
    .message-image-sec {
      display: flex;
      justify-content: space-between;
      .message-left-sec {
        .message-type {
          font-family: $primaryFont;
          font-size: 16px;
          line-height: 21px;
          color: $color-pearl;
          text-transform: capitalize;
        }
      }
    }
    .reply-close-sec {
      width: 10%;
      text-align: center;
      align-self: center;
      svg {
        cursor: pointer;
      }
    }
  }
  .message-seen-by-sec {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    .message-seen-by-text {
      font-family: $primaryFont;
      font-size: 12px;
      line-height: 18px;
      padding-right: 5px;
      color: #438ac1;
    }
    .message-seen-name {
      font-family: $primaryFont;
      font-size: 12px;
      line-height: 18px;
      color: $color-pearl;
      text-transform: capitalize;
    }
  }
  .update-right-chat-sec {
    border-bottom: $blockBorder;
    padding: 24px 18px 18px;
    // max-height: 335px;
    // min-height: 350px;
    box-sizing: border-box;

    .group-members-sec {
      .group-member-add-sec{
        display: flex;
        justify-content: space-between;
        margin-bottom: 9px;
        .groupmembers-text {
          font-family: $primaryFont;
          font-size: 16px;
          line-height: 21px;
          color: $color-white;        
        }
        .add-icon{
          padding: 0;
        }
      }
      .group-members-details-sec{
        display: flex;
        justify-content: space-between;
        padding: 9px 0;
        .group-members{
          display: flex;
          .group-members-profile {
            width: 30px;
            height: 30px;
            margin-right: 12px;
          }
          .group-members-name {
            font-family: $primaryFont;
            font-size: 16px;
            line-height: 21px;
            color: $color-white;
            align-self: center;
          }
        }
        .delete-member{
          padding: 0;
        }
      }
    }

    .update-button {
      padding: 14px 0px;
      font-size: 21.33px;
      line-height: 26px;
      font-weight: 400;
      font-family: $authHeaderFont;
      background-color: $color-blue;
      color: $color-white;
    }
  }
}
