@import '../../../../../../../assets/scss/variable.scss';

.fight-record-cover {
  position: relative;
  margin-top: 4px;
  // padding-top: 12px;
  background: #202020;
  border-radius: $bRadius;
  .fightsidename,
  .fightname,
  .event-names {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
  }
  .username {
    cursor: pointer;
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  // .fightname {
  //   padding-bottom: 10px;
  // }
  .ptb-12 {
    padding: 12px 0;
  }
  // .fight-record-top {
  //   // display: flex;
  //   padding: 0px 12px;
  //   margin-bottom: 9px;
  //   .fight-detail {
  //     width: 50%;
  //     .fighter-name {
  //       cursor: pointer;
  //       font-size: 16px;
  //       line-height: 21px;
  //       font-weight: 500;
  //       font-family: $primaryFont;
  //       color: $color-white;
  //       white-space: nowrap;
  //       width: 70%;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //     }

  //     .fighter-class {
  //       font-size: 12px;
  //       line-height: 16px;
  //       font-weight: 400;
  //       font-family: $primaryFont;
  //       color: #b4babf;
  //     }
  //   }

  //   .fight-address {
  //     width: 50%;
  //     .fight-date {
  //       display: flex;
  //       justify-content: space-between;
  //       align-items: center;
  //       font-size: 12px;
  //       line-height: 16px;
  //       font-weight: 500;
  //       font-family: $primaryFont;
  //       color: $color-white;
  //       .record-actions {
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         .MuiIconButton-root {
  //           padding: 0;
  //           margin-left: 10px;
  //         }
  //       }
  //     }

  //     .fight-names {
  //       font-size: 12px;
  //       line-height: 16px;
  //       font-weight: 400;
  //       font-family: $primaryFont;
  //       color: $color-white;
  //     }
  //   }
  // }
  .fight-record-top-sec {
    padding: 12px;
    .fight-edit-delete-icon{
      text-align: right;
    }
    .fight-record-sec{
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      .fight-record-details{
          width: 50%;
          .fightname{
            white-space: nowrap;
            width: 98%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
      }
    }
    .fight-combatant-scroll{
      max-height: 270px;
      overflow-x: hidden;
      overflow-y: scroll;
      padding-right: 5px;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: $color-black-very-light;
        border-radius: $bRadius;
        padding: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: $color-blue;
        border-radius: $bRadius;
      }
      .fight-combatant-sec{
        // padding-top: 12px;
        .fighter-name {
          font-size: 16px;
          line-height: 21px;
          font-weight: 500;
          font-family: $primaryFont;
          color: $color-white;
        }
        .text-right{
          text-align: right;
        }
        .text-blue{
          color: $color-blue;
        }
        .text-gray{
          color: $color-pearl;
        }
        .method-text{
          padding-top: 8px;
          text-transform: uppercase;
        }
        .vs-text{
          text-align: center;
          align-self: center;
        }
        .fight-combatant{
          padding-top: 8px;
        }
      } 
    }
     
  }
  .fight-record-bottom {
    display: flex;
    padding: 0px 12px 18px 12px;
    justify-content: space-between;

    .fight-result-bx {
      position: relative;
      width: 25%;
      .result-type {
        font-size: 12px;
        line-height: 16px;
        font-family: $primaryFont;
        font-weight: 400;
        color: #b4babf;
        text-transform: uppercase;
      }

      .fight-names {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
        text-transform: capitalize;
      }

      .result-value {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        font-family: $primaryFont;
        color: $color-white;
      }
    }
  }

  .view-fight-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 12px 10px 12px;
    border-top: 1px solid #2e353a;
    .MuiIconButton-root {
      padding: 0;
      margin-right: 5px;
    }
    .add-endorsement-btn {
      display: flex;
      align-items: center;
      position: relative;

      .endrosement-txt {
        cursor: pointer;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        font-family: $primaryFont;
        color: #b4babf;
        text-transform: capitalize;
        display: flex;
        align-items: center;
      }
      .endrosement-count {
        width: 21px;
        height: 21px;
        background: $color-blue;
        border-radius: 50%;
        font-size: 12px;
        display: flex;
        align-items: center;
        color: $color-white;
        justify-content: center;
        line-height: 15px;
        margin-right: 6px;
        cursor: pointer;
      }
    }
  }
}
