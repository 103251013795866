@import '../../../../../assets/scss/variable.scss';

//Notification item
.notification-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10.5px 14px;
  border: 1px solid $color-white;
  margin-bottom: 11px;
  @media (max-width: 599px) {
    padding: 8px 6px;
  }
  .noti-left {
    display: flex;
    align-items: center;
    .time-ago {
      font-size: 12px;
      font-family: roboto;
    }
    .notification-image {
      margin-right: 9px;
      border-radius: 50%;
      background: $color-black;
      padding: 4px;
      display: flex;
      border: 2px solid $color-lava;
      @media (max-width: 599px) {
        margin-right: 6px;
      }
      .MuiAvatar-root {
        width: 48px;
        height: 48px;
        @media (max-width: 599px) {
          width: 30px;
          height: 30px;
        }
      }
    }
    .notification-description {
      position: relative;
      .desc-txt {
        font-size: 21px;
        line-height: 28px;
        font-family: $authHeaderFont;
        color: $color-white;
        @media (max-width: 599px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  .noti-right {
    display: flex;
    align-items: center;
  }
}
