@import '../../../../assets/scss/variable.scss';

.frecords-container {
  height: 100%;
  .fr-grid-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1279px) {
      display: block;
    }

    // FIGHT RECORDS LEFT SIDE
    .fr-left {
      position: relative;
      width: 36.548%;
      height: 863px;
      background: $blockColor;
      border: $blockBorder;
      border-radius: $bRadius;
      @media (max-width: 1279px) {
        height: auto;
        width: 100%;
      }
    }

    .fr-right {
      width: 63.452%;
      min-height: 863px;
      padding-left: 16.99px;
      @media (max-width: 1279px) {
        width: 100%;
        min-height: auto;
        margin-top: 18px;
        padding-left: 0;
      }
      .fr-content {
        width: 100%;
        height: 100%;
      }
    }
  }
}
