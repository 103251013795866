@import '../../../assets/scss/variable.scss';

.combat-dialog {
  ::-webkit-scrollbar {
    width: 0;
  }
  .MuiDialog-paper {
    box-shadow: none;
    border-radius: $bRadius;
    max-width: 790px;
    width: 100%;
    padding: 36px 36px 0px 36px;
    background-color: $blockColor;
    // border: 1px solid #707070;
    @media (max-width: 599px) {
      padding: 15px 12px;
    }
    @media (max-width: $mobileBreakPoint) {
      margin: 0;
      // position: absolute;
      top: 0;
      bottom: 0;
      min-height: calc(100% - 32px);
      min-width: 100%;
      padding-top: 30px;
      overflow-x: hidden;
      position: absolute;
      padding-bottom: 0;
    }
    .MuiDialogTitle-root {
      padding: 0;
    }

    .combat-dialog-title {
      position: relative;
      .dialog-title {
        width: 100%;
        margin-top: 3px;
        font-size: 37.92px;
        line-height: 47px;
        color: $color-white;
        font-family: $authHeaderFont;
        text-align: center;
        margin-bottom: 19.5px;

        @media (max-width: 599px) {
          font-size: 26px;
          margin-top: 19.5px;
        }

        svg {
          @media (max-width: 599px) {
            width: 15px;
            height: 15px;
          }
        }
      }
      .dialog-close {
        position: absolute;
        right: 0;
      }
    }
    // @media (max-width:480px){
    //   margin: 0%;
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   min-height: calc(100% + 16px);
    //   width: 100%;
    //   padding-top: 0;
    //   overflow-x: hidden;
    //   .dialog-content-box {
    //     position:sticky;
    //     top:0;
    //     padding-top:10px;
    //     z-index: 2;
    //     background-color: #171717;
    //     width: 100%;
    //     margin-bottom:10px;
    //   }
    //   .dialog-submit-container {
    //     position: sticky;
    //     bottom: 0;
    //     background-color: #171717;
    //     padding-top: 0;
    //     margin-top: 20px;
    //     z-index: 2;
    //   }
    //   .dialog-submit-button {
    //     padding-bottom:25px;
    //     margin-top: 0;
    //   }
    // }
  }
}

.dialog-submit-button {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 32px 36px 0 36px;
  border-top: 1px solid #696969;
  margin: 0px -36px;
  margin-top: 12px;
  @media (max-width: 599px) {
    padding: 32px 36px 0 36px;
  }

  .dialog-submit {
    border-radius: $bRadius;
    width: 100%;
    font-size: 21.33px;
    line-height: 26px;
    font-family: $authHeaderFont;
    color: $color-black-very-light;
    background-color: $color-white;
  }
}
.mr-5 {
  margin-right: 5px;
}
.autocomplete-dropdown-container {
  .MuiMenuItem-root {
    white-space: unset;
  }
}

.claim-profile-dialog {
  @media (max-width: $mobileBreakPoint) {
    .MuiDialog-paper {
        margin: 0;
        min-width: 100%;
        min-height: 100%;
        .combat-dialog-title {
          .dialog-title {
            text-align: start;
          }
        }
    }
    .dialog-submit-button {
      position: fixed;
      bottom: 0;
      width: 100%;
      margin-left: -12px;
      background-color: #171717;
      padding:16px 36px
    }
  }
}

.mobile-drawer {
  .combat-mobile-drawer-title {
    position: relative;
    margin-top: 10px;
    .mobile-drawer-title {
      width:100%;
      margin-top:3px;
      font-size: 25px;
      line-height: 30px;
      color:$color-white;
      font-family: $primaryFont;
      font-weight: bold;
      margin-bottom: 19px;
    }
  }
}