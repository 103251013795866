@import '../../../../../assets/scss/variable.scss';

.generalbadge_block {
  .general_title {
    font-size: 37.92px;
    line-height: 47px;
    font-family: $authHeaderFont;
    padding-bottom: 18px;
  }
  .general_subtitle {
    font-size: 21.33px;
    line-height: 26px;
    font-family: $authHeaderFont;
    padding-bottom: 10px;
  }

  .badge_block {
    display: flex;
    align-items: center;
    padding: 18px;
    border: $blockBorder;
    background-color: #202020;
    border-radius: $bRadius;
    height: 100%;
    .icon_block {
      display: flex;
      width: 60px;
    }
    .badge_content {
      padding-left: 9px;
      .block_title {
        font-family: $primaryFont;
        font-size: 16px;
        line-height: 21px;
        text-transform: capitalize;
        font-weight: 500;
      }
      .nbsb_title {
        font-family: $primaryFont;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: $color-pearl;
      }

      .achieved_date {
        color: $color-blue;
      }
    }
  }
  .badge_block_wraper {
    padding-top: 35px;
  }
  .badge_signup {
    background-color: #2e353a;
  }
}
