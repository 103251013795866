@import '../../../../assets/scss/variable.scss';

.connection-page {
  .infinity-scroll-view-custom {
    height: 700px;
    overflow: auto;
    display: flex;
    width: 100%;

    @media (max-width: $mobileBreakPoint) {
      height: 100%;
      overflow: visible;
    }
  }

  .back-to-profile {
    padding-bottom: 26px;
    .back-text {
      padding: 0;
      font-size: 21.33px;
      font-family: $authHeaderFont;
      color: $color-white;
      @media (max-width: 599px) {
        font-size: 16px;
        padding: 8px 0;
      }
    }
    @media (max-width: 599px) {
      padding-bottom: 10px;
    }
  }
  .search-field-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    border: none;
    margin-bottom: 18px;

    .search-btn {
      padding: 0px 18px;
      height: 45px;
      background-color: $color-blue;
      color: $color-white;
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      font-family: $authHeaderFont;
      margin-left: 9px;
    }
  }
  .p-0 {
    padding: 0;
  }
  .connection-page-title {
    font-size: 37.92px;
    line-height: 47px;
    color: $color-white;
    font-family: $authHeaderFont;
    text-align: center;
    margin-bottom: 19.5px;
    @media (max-width: 599px) {
      font-size: 24px !important;
      line-height: 30px !important;
    }
  }

  .usercard-skeleton {
    min-height: 200px;
    width: 100%;
  }

  .user-filters {
    margin-bottom: 18px;
  }

  .connection-user-list {
    position: relative;
    border: $blockBorder;
    border-radius: 3px;
    padding: 25px;
    background: $blockColor;
    // .connectin-userlist-inner {
    // border: $blockBorder;
    // border-radius: 3px;
    // padding: 25px;
    // background: $blockColor;
    // }
    @media (max-width: 599px) {
      padding: 12px;
    }
  }
  .visit-button {
    background-color: $color-blue;
    color: $color-white;
  }
  .no-data-text {
    margin-top: 50px;
    margin-bottom: 18px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #3d3e3f;
  }
}
.mt-20 {
  margin-top: 20px;
}
.m-auto {
  margin: 0 auto !important;
}
.mb-18 {
  margin-bottom: 18px;
}
.actions-header {
  position: relative;
  // margin-bottom: 18px;
  // padding: 18px 18px 14px 18px;

  // .MuiTabs-flexContainer {
  // justify-content: space-between;
  // }
  .MuiButtonBase-root {
    &:hover {
      background: $color-gray-dark;
    }
  }
  .MuiTabs-root,
  .MuiTab-root {
    min-height: 0;
    @media (min-width: 600px) {
      min-width: 0;
    }
  }

  .MuiTab-root {
    padding: 5px 12px;
    border-radius: $bRadius;
  }

  .Mui-selected {
    background-color: $color-blue;
    .MuiTab-wrapper {
      color: $color-white;
    }
    &:hover {
      background-color: $color-blue;
    }
  }

  .MuiTab-wrapper {
    font-size: 16px;
    line-height: 20px;
    font-family: $authHeaderFont;
    color: #b4babf;
  }

  .MuiTabs-indicator {
    display: none;
  }
}
.act-data-list {
  padding: 18px;
  .MuiTabPanel-root {
    padding: 0;
  }

  .activity-tab {
    .tab-title {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      line-height: 20px;
      font-family: $authHeaderFont;
      color: $color-white;
    }

    ::-webkit-scrollbar {
      width: 0;
    }

    // CFFC Data
    .cffc-data-container {
      position: relative;
      max-height: 220px;
      overflow-y: scroll;
      margin-top: 5px;
    }

    .no-data-txt {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      font-family: $primaryFont;
      text-align: center;
      color: $color-spanish-gray;
      margin-top: 16px;
      margin-bottom: 7px;
    }
  }
}
.infinity-scroll-view {
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
}
.infinite-scroll-component__outerdiv ::-webkit-scrollbar {
  display: none;
}
