@import '../../../../../assets/scss/variable.scss';

.referral-main {
  .ref-head {
    font-size: 37.92px;
    font-family: $authHeaderFont;
    line-height: 47px;
  }
  .about-referral {
    .content {
      font-size: 16px;
      font-family: $primaryFont;
      padding-top: 18px;
    }
  }
  .yrc-main {
    border: $blockBorder;
    background: $blockColor;
    padding: 18px !important;
    .referral-codes {
      .rc-title {
        font-size: 28.45px;
        font-family: $authHeaderFont;
        line-height: 36px;
      }
    }
  }
  .codes-table {
    .MuiTableHead-root {
      background: #2e353a;
      color: $color-pearl;
    }
    .logo-anchor {
      color: $color-white;
      text-decoration: underline;
    }
    .cursor-pointer {
      cursor: pointer;
    }
    .row-right {
      display: flex;
      justify-content: right;
      align-items: center;
      cursor: pointer;
      .add-btn {
        padding: 0;
        font-size: 12px;
        font-weight: 400;
        color: $color-white;
        font-family: $primaryFont;
        line-height: 18px;
      }
      svg {
        margin-right: 8px;
      }
    }
    .btn-toggle {
      padding: 0;
      svg {
        width: 15px;
        height: 15px;
        fill: $color-white;
        margin-left: 15px;
      }
    }
    .MuiTableCell-head {
      color: $color-pearl !important;
      font-size: 12px !important;
      line-height: 16px;
      font-family: $primaryFont;
      padding: 8px 9px !important;
      font-weight: 400;
      text-transform: uppercase;
    }
    .MuiTableCell-root {
      color: $color-white;
      font-size: 13px;
      padding: 8px 9px;
      border: 0;
    }
    .MuiTableRow-root {
      border-bottom: $blockBorder;
    }
    .ref-name {
      display: flex;
      align-items: center;
      svg {
        padding-right: 3px;
        height: 18px;
        width: 18px;
      }
      .p-0 {
        padding: 0;
      }
    }
    .d-flex {
      display: flex;
      align-items: center;
      justify-content: right;
      svg {
        padding-right: 3px;
      }
    }
  }
  .my-referrals {
    padding-top: 45px;
    .referral-codes {
      background: $blockColor;
      padding: 24px 17px;
      border: $blockBorder;
      margin-top: 10px;
      .referral-search-box {
        .search-field-box {
          padding: 0;
          margin-top: 18px;
          border: 0;
        }
      }
    }
  }
  .p-0 {
    padding: 0;
  }
  .mt-18 {
    margin-top: 18px;
  }
  .no-data-txt {
    font-size: 12px !important;
    line-height: 16px;
    font-weight: 400;
    font-family: Roboto;
    text-align: center;
    color: #9d9d9d !important;
    margin-top: 16px;
    margin-bottom: 7px;
  }
}
