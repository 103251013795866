@import '../../../../../assets/scss/variable.scss';

.newsfeed-left-box {
  .infinity-scroll-view-custom {
    height: 1300px;
    overflow: auto;
    display: flex;
    width: 100%;

    @media (max-width: $mobileBreakPoint) {
      height: 100%;
      overflow: visible;
    }
  }

  .bpdtr-box {
    background-color: $blockColor;
    border: $blockBorder;
    border-radius: $bRadius;
    padding: 18px;
    margin-bottom: 9px;
    display: flex;

    .Mui-selected {
      background-color: $color-blue;
    }

    .MuiTab-wrapper {
      flex-direction: row;
    }

    //.MuiTab-textColorInherit {
    //  opacity: 1;
    //}

    .MuiTab-labelIcon {
      padding: 0;
      min-height: 42px;

      .MuiTab-wrapper > svg {
        margin-bottom: 0px;
        margin-right: 10.1px;
      }
    }
    .MuiTabs-indicator {
      display: none;
    }
    .MuiTabs-root.tab-button {
      min-height: 42px;
    }
    .MuiTab-root,
    .tab-button {
      font-size: 18px;
      line-height: 22px;
      color: $color-white;
      font-family: $authHeaderFont;
      border-radius: $bRadius;
      padding: 0px 10px;

      @media (min-width: 600px) {
        min-width: 0px;
      }
    }
    .tab-button {
      min-width: auto;
    }
    .tablist-sec {
      .MuiTabs-flexContainer {
        display: flex;
        overflow-x: scroll;
      }
      .MuiTabs-flexContainer::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .infinity-scroll-view {
    .infinite-scroll-component {
      overflow-x: hidden !important;
      overflow-y: auto !important;
    }
    .infinite-scroll-component__outerdiv {
      width: 100%;
    }
  }
  .infinity-scroll-view::-webkit-scrollbar {
    display: none;
  }
}
