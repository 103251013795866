@import '../../../../../assets/scss/variable.scss';

.question-header {
  position: relative;

  .MuiTabs-root,
  .MuiTab-root {
    min-height: 0;
    @media (min-width: 600px) {
      min-width: 0;
    }
  }
  .MuiButtonBase-root {
    &:hover {
      background: $color-white;
    }
  }
  .MuiTab-textColorInherit {
    opacity: 1;
  }
  .MuiTab-root {
    padding: 4px 9px;
    border-radius: $bRadius;
    background-color: $color-white;
  }
  .MuiTab-root:not(:first-child) {
    margin-left: 9px;
  }

  .Mui-selected {
    background-color: $color-blue;
    .MuiTab-wrapper {
      color: $color-white;
    }
    &:hover {
      background-color: $color-blue;
    }
  }

  .MuiTab-wrapper {
    font-size: 18px;
    line-height: 22px;
    font-family: $authHeaderFont;
    color: $color-black;
  }

  .MuiTabs-indicator {
    display: none;
  }
}
.cat_tabpanel {
  padding: 25px 0 0 0 !important;
}
