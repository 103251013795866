@import '../../../../../../../assets/scss/variable.scss';

.mb-9 {
  margin-bottom: 9px;
}
.community-am-box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 14px;
}
.community-members-box {
  padding-top: 18px;
  padding-bottom: 16px;
  background-color: $blockColor;
  border-radius: $bRadius;
  border: $blockBorder;

  .member-title {
    padding: 0px 18px;
    font-size: 28.45px;
    line-height: 38px;
    font-weight: 500;
    font-family: $primaryFont;
    color: $color-white;
  }
  .active-request-tab-box {
    display: flex;
    padding: 14px 18px;
    .ar-tab {
      font-size: 16px;
      line-height: 20px;
      font-family: $authHeaderFont;
      color: $color-white;
      padding: 4px 9px;
      border-radius: $bRadius;
    }

    .ar-active {
      background-color: $color-blue;
    }
  }

  .no-tb {
    border-top: none !important;
  }
  .member-list-box {
    border-top: $blockBorder;
    padding: 18px 18px 0px 18px;

    .search-box {
      position: relative;
      .MuiOutlinedInput-input {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
      }
      .MuiOutlinedInput-inputMarginDense {
        padding-top: 8.5px;
        padding-bottom: 8.5px;
      }
    }

    // Member list
    .member-list-container {
      .MuiList-padding {
        padding-top: 0;
        padding-bottom: 0;
      }
      .MuiListItem-dense {
        padding: 6px 0px;
        border-bottom: $blockBorder;
      }
      .MuiListItem-secondaryAction {
        padding-left: 0px;
        padding-right: 0px;
      }

      .MuiListItemSecondaryAction-root {
        right: 0;
      }

      .MuiListItemAvatar-root {
        min-width: 0;
        margin-right: 12px;
        .MuiAvatar-root {
          width: 30px;
          height: 30px;
        }
      }

      .member-btn {
        padding: 0;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        font-family: $primaryFont;
        color: #b4babf;
        text-transform: inherit;
      }
    }
  }
  .no-member-txt {
    line-height: 16px;
    text-align: center;
    color: #9d9d9d;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 400;
    margin-top: 14px;
    margin-bottom: 7px;
  }
}
