@import '../../../../../../../../assets/scss/variable.scss';

.add-edit-descipline-box {
  position: relative;

  .dialog-submit-button {
    margin-top: 24px;
    @media (max-width:$mobileBreakPoint) {
      position: sticky;
      bottom: 15px;
      background-color: #272727;
      z-index:1;
      padding:20px 36px
    }
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }
  .blank-discipline-cover {
    position: relative;
    // height: 150px;
    // @media (min-width: 600px) {
    //   height: 350px;
    // }
  }
  .discipline-dropdown-cover {
    max-height: 513px;
    width: 100%;
    background-color: #1b1b1b;

    .discipline-form-control {
      width: 100%;
    }
    .MuiChip-deletable {
      height: 21px;
      background-color: $color-blue !important;
      color: $color-white !important;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      font-family: $primaryFont;
      position: relative;
      top: -1px;

      svg {
        path {
          fill: $color-white;
        }
      }
    }
  }

  .add-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
    padding-bottom: 13.5px;
    border-bottom: $blockBorder;
  }

  .discipline-hr {
    margin-top: 27px;
    margin-bottom: 16px;
    height: 0px;
    border-bottom: $blockBorder;
  }

  .selected-d-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    font-family: $primaryFont;
    color: $color-white;
  }

  .MuiIconButton-root {
    padding: 0;
  }

  .discipline-partition {
    width: 100%;
    display: flex;
    justify-content: center;
    .discipline-partition-hr {
      width: 196px;
      margin-top: 13px;
      margin-bottom: 23px;
      height: 0;
      border-bottom: $blockBorder;
    }
  }
}
