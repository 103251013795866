@import '../../../../assets/scss/variable.scss';

.badge_block_wrapper {
  position: relative;
  margin-top: 9px;
  width: 100%;
  padding: 20px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  .back-to-profile {
    padding-bottom: 22px;
    .back-text {
      padding: 0;
      font-size: 21.33px;
      font-family: $authHeaderFont;
      color: $color-white;
      @media (max-width: 599px) {
        font-size: 16px;
        padding: 8px 0;
      }
    }
    @media (max-width: 599px) {
      padding-bottom: 10px;
    }
  }
  .badge_block {
    .badge_title {
      font-size: 28.45px;
      line-height: 36px;
      font-family: $authHeaderFont;
      padding: 0 0 13px 16px;
    }
  }

  .badge_follower {
    display: flex;
    align-items: center;
    padding: 18px;
    border: $blockBorder;
    background-color: #202020;
    border-radius: $bRadius;
    .badge_content {
      padding-left: 9px;
      .badge_block_title {
        font-family: $primaryFont;
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        text-transform: capitalize;
      }
      .achieved_date {
        font-family: $primaryFont;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
      }
    }
  }
}
