@import '../../../../assets/scss/variable.scss';

.edit-profile {
  .weight-range-title {
    font-size: 28.45px;
    line-height: 36px;
    font-family: $authHeaderFont;
    color: $color-white;
    padding-left: 4.5px;
    margin-bottom: 18px;
    margin-top: 40px;
  }

  .measurment-text {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: Roboto;
    color: $color-white;

  }

  .MuiRadio-root {

  }

  .weighclass-measurments {
    .MuiRadio-root {
      color: $color-white;
    }

    .MuiTypography-root {
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      font-family: Roboto;
      color: $color-white;
      margin-left: 9px;
    }
  }
}
