@import '../../../../../../assets/scss/variable.scss';

.fr-details {
  position: relative;
  margin-top: 9px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;

  // @media (min-width: 1400px) {
  //   margin-left: -15px;
  //   margin-right: -5px;
  // }

  .fr-content {
    width: 100%;
    position: relative;
    padding: 18px;
    padding-bottom: 0;

    .fr-ctop {
      width: 100%;

      .eventData {
        display: flex;
        margin-bottom: 38px;

        .fr-eimg {
          width: 214px;
          height: 101px;
          border-radius: 3px;
          margin-right: 14px;
        }
        .evntd {
          width: 100%;
          display: flex;
          align-items: flex-start;
          .evnt-lft,
          .evnt-rgt {
            // width: 50%;
            .bck-data {
              .databck {
                display: flex;

                .lftbck {
                  .bck-tle {
                    min-width: 73px;
                    font-size: 11px;
                    line-height: 18px;
                    font-weight: 400;
                    font-family: $primaryFont;
                    color: $color-pearl;
                    margin-right: 18px;
                    text-transform: uppercase;
                  }
                }
                .rgtbck {
                  .bck-cnt {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;
                    font-family: $primaryFont;
                    color: $color-white;
                    margin-right: 18px;
                  }
                }
              }
            }
          }
          .evnt-rgt {
            .rgtbck {
              .bck-cnt {
                margin-right: 0 !important;
              }
            }
          }
        }
      }

      .fstime {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        text-transform: uppercase;
        color: $color-pearl;
        font-family: $primaryFont;
        text-align: center;
      }

      .fgt-tlt {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
        text-align: center;
      }

      .fgtrs {
        display: flex;
        justify-content: center;
        margin: 18px 0;

        .fgtrs-vtxt {
          font-size: 12px;
          line-height: 15px;
          font-weight: 400;
          font-family: $primaryFont;
          color: $color-pearl;
          min-width: 69px;
          margin: auto 26px;
          text-align: center;
          text-transform: uppercase;
        }

        .fgtr1 {
          .fgtr-side,
          .fgtrname {
            text-align: right;
          }

          .fgtrimg {
            margin-left: 14px;
          }
        }
        .fgtr2 {
          .fgtr-side,
          .fgtrname {
            text-align: left;
          }

          .fgtrimg {
            margin-right: 14px;
          }
        }

        .fgtr1,
        .fgtr2 {
          display: flex;

          .fgtr-data {
            .fgtr-side {
              font-size: 12px;
              line-height: 15px;
              font-weight: 400;
              font-family: $primaryFont;
              color: $color-pearl;
              text-transform: uppercase;
            }
            .fgtrname {
              font-size: 28.45px;
              line-height: 28.45px;
              font-weight: 400;
              font-family: $authHeaderFont;
              color: $color-white;
              max-width: 100px;
            }
          }
          .fgtrimg {
            width: 80px;
            height: 80px;
          }
        }
      }
    }

    .fr-cbottom {
      border-top: $blockBorder;
      margin-left: -18px;
      margin-right: -18px;
      padding: 18px;
      text-align: center;
      border-bottom: $blockBorder;

      .fgd-i {
        display: flex;
        justify-content: center;
        align-items: center;

        .lft {
          text-align: right;
        }

        .rgt {
          text-align: left;
        }

        .fgtri {
          width: 100%;
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          font-family: $primaryFont;
          color: $color-white;
        }

        .fgthead {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          font-family: $primaryFont;
          color: $color-pearl;
          text-transform: uppercase;
          max-width: 86px;
          margin: 9px 18px;
        }
      }
    }

    .frd-acts {
      display: flex;
      justify-content: flex-end;
      padding: 8px 13.2px;
    }
  }
}
