@import '../../../../../../../assets/scss/variable.scss';

.create-new-community {
  position: relative;
  padding-top: 27px;
  .restriction-box {
    margin-top: 0 !important;
    padding: 0;
    border: 0;
  }
  .endorsement-main {
    padding: 0;
    border: 0;
  }
  .community-img-txt {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
    padding-bottom: 18px;
  }

  //   Community Image
  .profile-picture-box {
    display: flex;
    align-items: flex-end;
    margin-bottom: 36px;
    @media (max-width: 1279px) {
      justify-content: center;
    }

    @media (max-width: 599px) {
      display: block;
    }
    .MuiIconButton-root {
      padding: 0;
      margin-right: 16px;
      width: 115px;
      height: 115px;
    }

    .MuiAvatar-root {
      width: 115px;
      height: 115px;
    }
    .camera-icon {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }

    .profile-pic-recomandations {
      position: relative;
      @media (max-width: 599px) {
        margin-top: 30px;
      }

      .rec-heding {
        font-size: 12px;
        line-height: 18px;
        font-weight: 700;
        font-family: $primaryFont;
        color: $color-white;
      }
      .rec-types {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
      }
    }
  }

  //   Community | Official Switch Button
  .comm-off-switch-btn {
    margin-bottom: 18px;
    .selected-com-type {
      width: 87px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
      text-transform: capitalize;
      background-color: $color-blue;
    }
    .other-com-type {
      width: 87px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
      text-transform: capitalize;
    }
  }

  .com-create-form-content {
    .field-character-count {
      float: right;
      margin-right: -14px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
    }

    .restriction-box {
      margin-top: 43px;

      .restriction-txt {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
        margin-bottom: 9px;
      }

      .restriction-chips {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        &::-webkit-scrollbar {
          display: none;
        }
        .rest-chip {
          height: 21px;
          margin-top: 9px;
          margin-right: 3px;
          color: $color-spanish-gray;
          background-color: $color-gray-dark;
          display: flex;
          align-items: center;
          @media (max-width: 599px) {
            margin-right: 9px;
          }
          .MuiChip-iconSmall {
            width: 13px;
            height: 13px;
          }
          .MuiChip-labelSmall {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            font-family: $primaryFont;
            position: relative;
            top: -1px;
          }
        }

        .checked-chip {
          background-color: $color-blue !important;
          color: $color-white !important;
        }
      }
    }
  }
}
