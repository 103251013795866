@import '../../../../assets/scss/variable.scss';

.reaction-sec{
    .tab-sec{          
        min-width: 30px;
        min-height: 20px;
        font-family: $authHeaderFont;
        font-size: 16px;
        line-height: 20px; 
        padding: 6px 10px;
    }
    .MuiTabs-root{
        .MuiTab-wrapper {
            flex-direction: row;
            color: $color-white;
            svg{
                width: 25px;
                height: 25px;
                padding-right: 5px;
                margin-bottom: 0;
            }
        }
        .MuiTab-textColorInherit{
            opacity: 1;
        }
        .MuiTabs-indicator{
            background-color: $color-blue;
            height: 3px;
        }
    }
    .reaction-tabpanel-sec{
        padding: 24px 0;
    }
}
.reacted-data-sec{
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid $color-msg-left;
    .reacted-user-profile-sec{
        position: relative;
        .user-profile{
            width: 60px;
            height: 60px;
        }
        .user-reacted{
            width: 21px;
            height: 21px;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    .reacted-user-sec{
        padding-left: 20px;
        align-self: center;
        .user-name-sec{
            display: flex;
            .user-name{
                font-size: 18px;
                line-height: 27px;
                font-family: $primaryFont;
                color: $color-white;
            }
            .user-sign{
                padding: 0;
                padding-left: 10px;
                svg{
                    width: 15px;
                    height: 15px;
                }
              
            }
        }     
        .user-skill{
            font-size: 16px;
            line-height: 20px;
            font-family: $primaryFont;
            color: $color-pearl;
        }
    }
}
.reacted-data-sec:last-child{
    border-bottom: 0;
}