@import '../../../../../../assets/scss/variable.scss';

.messages-left-side-sec {
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  padding: 47px 9px 0px 11px;
  // height: 100%;
  .messages-title-sec {
    display: flex;
    justify-content: space-between;
    .message-text {
      font-family: $authHeaderFont;
      font-size: 20px;
      line-height: 25px;
    }
    .write-icon {
      cursor: pointer;
      .svg-write-icon {
        fill: $color-check;
        width: 21px;
        height: 21px;
        g#Rectangle_485 {
          stroke: $color-check;
        }
        path {
          stroke: $color-check;
        }
      }
    }
  }
  .Messages-search-sec {
    // position: relative;
    // padding: 18px;
    // background: $blockColor;
    // border-radius: $bRadius;
    // border: $blockBorder;
    margin-top: 20px;
    background-color: $color-lime-black;
    .MuiInputAdornment-root {
      svg {
        width: 22px;
        height: 22px;
      }
    }
    .MuiOutlinedInput-inputMarginDense {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .MuiOutlinedInput-adornedStart {
      padding-left: 7px;
    }
    .MuiInputBase-input {
      font-size: 16px;
      line-height: 21px;
      font-family: $primaryFont;
      color: $color-white;
      &::placeholder {
        color: $color-white;
      }
    }
  }
  .message-person-details {
    max-height: 60vh;
    overflow: scroll;
    min-height: 514px;
    .loader-chat{

      display: flex;
      margin: 10px auto 0;
    }
  }
  .message-person-details::-webkit-scrollbar {
    display: none;
  }
}
