@import '../../../../../../assets/scss/variable.scss';

.social-activity-container {
  position: relative;
  margin-top: 9px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;

  .actions-header {
    position: relative;
    padding: 18px 18px 14px 18px;
    border-bottom: $blockBorder;
    @media (max-width: 599px) {
      padding: 12px !important;
    }
    .MuiTabs-flexContainer {
      justify-content: space-between;
      @media (max-width:$mobileBreakPoint) {
        justify-content: flex-start;
      }
    }
    .MuiTabs-root{
      overflow-x: scroll;
      &::-webkit-scrollbar {
          display: none;
        }
    }
    .MuiTabs-root,
    .MuiTab-root {
      min-height: 0;
      @media (min-width: 600px) {
        min-width: 0;
      }
    }
    .MuiButtonBase-root {
      &:hover {
        background: $color-gray-dark;
      }
    }
    .MuiTab-root {
      padding: 5px 9px;
      border-radius: $bRadius;
    }

    .Mui-selected {
      background-color: $color-blue;
      .MuiTab-wrapper {
        color: $color-white;
      }
      &:hover {
        background-color: $color-blue;
      }
    }

    .MuiTab-wrapper {
      font-size: 16px;
      line-height: 20px;
      font-family: $authHeaderFont;
      color: #b4babf;
    }

    .MuiTabs-indicator {
      display: none;
    }
  }
  .act-data-list {
    padding: 18px;
    @media (max-width: 599px) {
      padding: 12px;
    }
    .MuiTabPanel-root {
      padding: 0;
    }

    .activity-tab {
      .tab-title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        line-height: 20px;
        font-family: $authHeaderFont;
        color: $color-white;
      }

      ::-webkit-scrollbar {
        width: 0;
      }

      // CFFC Data
      .cffc-data-container {
        position: relative;
        max-height: 220px;
        overflow-y: scroll;
        margin-top: 5px;
      }

      .no-data-txt {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        font-family: $primaryFont;
        text-align: center;
        color: $color-spanish-gray;
        margin-top: 16px;
        margin-bottom: 7px;
      }
    }
  }
}
