@import '../../../../assets/scss/variable.scss';

.account-setting-sec{
    .account-setting-grid{
    column-gap: 16px;
    @media (max-width:900px) {
        display: flex;
        flex-direction: column;
    }
    .account-setting-left-sec{
        border: $blockBorder;
        border-radius: 3px;
        padding: 18px;
        background: $blockColor;
        width: 30%;
        @media (max-width: 900px) {
            width: 100%;
        }
    }
    .account-setting-right-sec{
        border: $blockBorder;
        border-radius: 3px;
        background: $blockColor;
       width: 68%;
       @media (max-width: 900px) {
        width: 100%;
    }
    }
}
}