@import '../../../../../assets/scss/variable.scss';

// .organisation-form-img {
//   .my-right-top {
//     padding: 0 0 14px 13px;
//     width: 100%;
//     background: $blockColor;
//     border: none;
//     border-radius: $bRadius;
//     @media (max-width: 599px) {
//       padding: 12px;
//     }

//     .my-right-img {
//       width: 100%;
//       display: flex;
//       @media (max-width: 599px) {
//         display: block;
//       }
//       .my-right-avatar {
//         .right-img {
//           // position: relative;
//           // border-radius: 50%;
//           @media (max-width: 599px) {
//             text-align: center;
//             margin-bottom: 14px;
//             display: flex;
//             justify-content: center;
//           }
//           .MuiIconButton-root {
//             padding: 0;
//           }
//           .MuiAvatar-root {
//             width: 115px;
//             height: 115px;
//           }
//           .camera-icon {
//             position: absolute;
//             right: 0;
//             bottom: 0;
//           }
//         }
//       }
//     }
//   }
// }
.profile-picture-box {
  display: flex;
  align-items: flex-end;
  margin-bottom: 36px;
  @media (max-width: 1279px) {
    justify-content: center;
  }

  @media (max-width: 599px) {
    display: block;
  }
  @media (max-width: $mobileBreakPoint) {
    display: flex;
  }
  .MuiIconButton-root {
    padding: 0;
    margin-right: 16px;
    width: 115px;
    height: 115px;
  }

  .MuiAvatar-root {
    width: 115px;
    height: 115px;
  }
  .camera-icon {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  .profile-pic-recomandations {
    position: relative;
    @media (max-width: 599px) {
      margin-top: 30px;
    }

    .rec-heding {
      font-size: 12px;
      line-height: 18px;
      font-weight: 700;
      font-family: $primaryFont;
      color: $color-white;
    }
    .rec-types {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
    }
  }
}
.dialog-submit-button {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 32px 36px 36px 36px;
  border-top: 1px solid #696969;
  margin: 0px -36px;
  margin-top: 12px;

  .dialog-submit {
    background-color: $color-blue;
    border-radius: $bRadius;
    width: 100%;
    font-size: 21.33px;
    line-height: 26px;
    font-family: $authHeaderFont;
    color: $color-white;
    &:disabled {
      background-color: $color-fb;
    }
  }
}
.combat-dialog.your-org-dialog {
  .MuiDialog-paper {
   @media (max-width:$mobileBreakPoint){
      margin: 0%;
      position: absolute;
      top: 0;
      bottom: 0;
      min-height: calc(100% - 32px);
      width: 100%;
      padding-top: 0;
      overflow-x: hidden;
      .dialog-content-box {
        position:sticky;
        top:0;
        padding-top:10px;
        z-index: 2;
        background-color: #171717;
        width: 100%;
        margin-bottom:10px;
      }
      .dialog-submit-container {
        position: sticky;
        bottom: 0;
        background-color: #171717;
        padding-top: 0;
        margin-top: 20px;
        z-index: 2;
      }
      .dialog-submit-button {
        padding-bottom:25px;
        margin-top: 0;
      }
    }
  }
}