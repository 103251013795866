@import '../../../../assets/scss/variable.scss';

.community-post {
  margin-top: 9px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  .fill-blue {
    path {
      fill: $color-blue;
    }
  }
  .fill-red {
    path {
      fill: #c01515;
    }
  }
  .post-title {
    font-size: 16px;
    line-height: 21px;
    font-weight: 700;
    font-family: $primaryFont;
    color: $color-white;
    margin-bottom: 3px;
    cursor: pointer;
  }
  .post-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 18px 18px 19px 18px;
    @media (max-width: 599px) {
      padding: 12px;
    }
    // HEADER LEFT
    .header-left {
      width: 70%;
      .user-box {
        display: flex;
        margin-bottom: 14px;

        .user-img {
          width: 45px;
          height: 45px;
        }
        .name-time {
          width: 100%;
          margin-left: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .user-name-verification-sec{
            display: flex;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
            svg{
              align-self: center;
            }
          }
          .user-name {
            font-size: 16px;
            line-height: 21px;
            font-weight: 400;
            font-family: $primaryFont;
            color: $color-white;
            cursor: pointer;
            padding-right: 12px;
          }
          .post-time {
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            font-family: $primaryFont;
            color: #6a6a6a;
          }
        }
      }

      // ACTIVITY NAME
      .activity-type {
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        font-family: $primaryFont;
        color: $color-white;
        margin-bottom: 3px;
      }

      // COMMUNITY NAME
      .community-name {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        font-family: $primaryFont;
        color: $color-white;
        cursor: pointer;
      }

      .post-title {
        font-size: 16px;
        line-height: 21px;
        font-weight: 700;
        font-family: $primaryFont;
        color: $color-white;
        margin-bottom: 3px;
        cursor: pointer;
      }
      .post-time {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        font-family: $primaryFont;
        color: #6a6a6a;
        margin-bottom: 3px;
      }
      .posted-by {
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        font-family: $primaryFont;
        color: $color-white;
        cursor: pointer;
        word-break: break-word;
      }
    }

    // HEADER RIGHT
    .header-right {
      text-align: right;
      width: 60%;
      .stastics-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .statics-val {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          font-family: $primaryFont;
          color: $color-pearl;
        }

        .up-val {
          margin-right: 13px;
        }
        @media (max-width: 399px) {
          .MuiIconButton-root {
            padding: 12px 0;
          }
        }
      }
      .badges-box {
        // display: flex;
        margin-top: 9px;
        justify-content: flex-end;
        cursor: pointer;
        .badge-avatar {
          height: 20px;
          width: 20px;
          background: $color-white;
          border-radius: 50px;
          margin-left: 4px;
          display: inline-flex;
        }
        .badge-avatar:last-child {
          margin-right: 0;
        }
      }
    }
  }

  // POST CONTENT
  .post-content {
    position: relative;
    padding: 0px 18px 17px 18px;
    word-break: break-word;
    @media (max-width: 599px) {
      padding: 0px 12px 12px 12px;
    }
    .media-box {
      border: $blockBorder;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      .media-count {
        position: absolute;
        z-index: 111;
        // height: 100%;
        // width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
      }
    }
    // CONTENT TEXT
    .content-text {
      position: relative;
      font-size: 16px;
      line-height: 20.8px;
      margin-top: 3px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
      padding-bottom: 17px;
      a {
        color: $color-blue;
      }
      .wysiwyg-mention {
        color: $color-blue;
      }
      img {
        width: 100% !important;
      }
      @media (max-width: 599px) {
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 0px;
        text-align: justify;
      }
    }

    // CONTENT IMAGE
    .content-img {
      // width: 100px;
      // height: 100px;
      // margin: 5px;
      // min-width: 100px;
      // min-height: 100px;
      img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .h-100 {
        height: 100%;
      }
    }
    .post-image-slider {
      // margin-top: 10px;
      justify-content: center;
      display: flex;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      .content-img {
        width: 100%;
      }
      .MuiAvatar-root {
        width: 100%;
        height: 100%;
        border-radius: 0;
        // max-height: 350px;
      }
      &::-webkit-scrollbar {
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: $color-black-very-light;
        border-radius: $bRadius;
      }
      &::-webkit-scrollbar-thumb {
        background: $color-blue;
        border-radius: $bRadius;
      }
      // .content-img {
      //   width: 100px;
      //   height: 100px;
      //   margin: 5px;
      //   border-radius: 3px;
      // }
      .post-video-slider {
        height: 100%;
        width: 100%;
        // margin: 10px;
        // max-width: 242px;
        // max-height: 300px;
      }
      .post-video-slider:first-child {
        margin-left: 0;
      }
      // .react-player.audio-player {
      //   audio {
      //     width: revert !important;
      //   }
      // }
      .message-doc-view {
        svg {
          width: 24px;
          height: 24px;
        }
        .doc-link {
          text-transform: uppercase;
          display: flex;
          color: $color-white;
          text-decoration: none;
          justify-content: center;
        }
        .doc-name {
          margin: 0;
          padding-left: 5px;
        }
      }
    }
  }

  // POST ACTIONS
  .post-actions {
    border-top: $blockBorder;
    padding: 14px 18px 19px 18px;
    @media (max-width: 599px) {
      padding: 12px;
    }
    .post-act-btns {
      display: flex;
      justify-content: space-around;
      align-items: center;
      @media (max-width: 599px) {
        justify-content: flex-start;
      }
      .MuiGrid-item {
        @media (max-width: 599px) {
          min-width: 50%;
        }
      }

      .act-btn {
        padding: 0;
        min-width: 0;
        background-color: transparent;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-pearl;
        text-transform: capitalize;

        .MuiTouchRipple-root {
          display: none;
        }
        .MuiButton-startIcon {
          margin-right: 5.4px;
        }
      }
    }
  }
  // ADMIN BUTTON
  .admin-box {
    margin-top: 9px;
    .admin-btn {
      width: 100%;
      padding: 0;
      height: 30px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-light-blue;
      background-color: $color-blue;
      text-transform: capitalize;

      .MuiButton-endIcon {
        margin-left: 6.1px;
      }
    }
  }
}
.reaction-popover {
  .MuiPopover-paper {
    border-radius: 37px;
    padding: 5px 10px;
  }
  .react-icon {
    padding: 3px;
    .reaction-img {
      width: 25px;
      height: 25px;
    }
  }
  .react-selected {
    border: $blockBorder;
  }
}
.reaction-dialog-box {
  .MuiDialog-paper {
    min-width: 812px;
    @media (max-width: 959px) {
      max-width: 100%;
      min-width: 0;
    }
  }
}
.media-dialog-box.combat-dialog {
  .MuiDialog-paper {
    background-color: transparent;
    max-width: 100%;
    // height: 90vh;
    // max-height: 100%;
    height: 100%;
    padding: 0;
    margin-top: 0;
    .multislider-main {
      height: 100%;
    }
    .react-slideshow-container {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .combat-dialog-title {
      .dialog-close {
        position: absolute;
        top: 0;
        z-index: 111;
        @media (max-width: 599px) {
          top: 30px;
        }
      }
    }
  }
  .react-slideshow-wrapper {
    .images-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .multislider-main {
    .multimedia-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .message-doc-view {
        .doc-link {
          display: flex;
          color: $color-white;
          align-items: center;
          svg {
            font-size: 60px;
            margin-right: 15px;
          }
          .doc-name {
            font-size: 35px;
          }
        }
      }
      .MuiAvatar-root {
        // max-width: 600px;
        // max-height: 400px;
        max-width: 50%;
        width: auto;
        height: auto;
        border-radius: 0;
        @media (max-width: 959px) {
          height: auto !important;
        }
      }
      .react-player.audio-player {
        width: 400px;
        height: 35px;
        max-width: 65%;
      }
      .react-player.video-player {
        height: auto;
        @media (max-width: 959px) {
          max-width: 50%;
          margin: 0 auto;
        }
      }
    }
    @media (max-width: 959px) {
      .react-slideshow-container {
        button {
          width: 20px;
          height: 20px;
          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}
