@import '../../../../assets/scss/variable.scss';

.btn-groups-container {
  width: 100%;
  .btngroup-can-click {
    padding: 7px 0px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    text-transform: capitalize;
    background-color: $color-blue;
    color: #d9e8f3;
    border-radius: $bRadius;
    width: 100%;
    &:hover {
      background-color: $color-blue;
      color: #d9e8f3;
    }
}

  .btngroup-cannot-click {
      padding: 7px 0px;
      color: $color-white;
      background-color: $color-gray-dark;
      font-size: 12px;
      line-height: 18px;
      font-family: $primaryFont;
      text-transform: capitalize;
      border-radius: $bRadius;
      width: 100%;
      @media (max-width: 599px) {
        padding: 6px 0px;
      }
      &:hover {
        color: $color-white;
        background-color: $color-gray-dark;
      }
  }
  .btn-group-popper {
    z-index: 1;
    width: 40%;
    ul {
      padding: 0;
      li {
        font-size: 12px;
        font-family: $primaryFont;
      }
    }
  }
}

