@import '../../../../../../../assets/scss/variable.scss';

.event-container {
  padding: 18px;
  background-color: $blockColor;
  border-radius: $bRadius;
  border: $blockBorder;

  .event-title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    .event-title {
      font-size: 28.45px;
      line-height: 38px;
      font-weight: 500;
      font-family: $primaryFont;
      color: $color-white;
    }

    .MuiIconButton-root {
      padding: 0;
    }
  }

  .community-event-list-box {
    position: relative;
  }
  .no-data-txt {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: Roboto;
    text-align: center;
    color: #9d9d9d;
    margin-top: 16px;
    margin-bottom: 7px;
  }
}
