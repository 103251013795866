@import '../../../../../../assets/scss/variable.scss';


.user-organisation-sec{
    background-color: $blockColor;
    border: $blockBorder;
    padding: 18px;
    .all-tab-header{
        color: $color-white;
        font-size: 21.33px;
        line-height: 28px;
        font-family: $authHeaderFont;
    }
    .user-profile-sec{
        display: flex;
        margin: 9px 0 18px 0;
        .user-profile{
            width: 60px;
            height: 60px;
        }
        .user-details{
            align-self: center;
            padding-left: 18px;
            .user-wildcard{
                font-family: $authHeaderFont;
                font-size: 16px;
                line-height: 20px;
                color: $color-blue;
            }
            .username-sec{
                font-family: $authHeaderFont;
                font-size: 28.45px;
                line-height: 28.45px;
                color:$color-white;
                cursor: pointer;
                word-break: break-word
            }
            .member-since{
                font-family: $authHeaderFont;
                font-size: 12px;
                line-height: 15px;
                color: $color-pearl;
            }
        }
    }
    .address-block{
        padding: 19px 0 27px;
    }
        .address-title{
            font-family: $authHeaderFont;
            font-size: 16px;
            line-height: 20px;
            color: $color-white;
        }
        .address-text{
            font-family: $primaryFont;
            font-size: 16px;
            line-height: 20px;
            color: $color-white;
            word-break: break-word;
        }
        .see-all-btn{
            padding: 6px 16px;
            color: $color-light-blue;
            background-color: $color-blue;
            font-size: 12px;
            line-height: 18px;
            font-family: $primaryFont;
            text-transform: capitalize;
            border-radius: $bRadius;
            width: 150px;
        }
        .see-all-btn:hover{
            color: $color-light-blue;
            background-color: $color-blue;
        }
}