@import '../../../../../../../assets/scss/variable.scss';

.messages-chats-box {
  margin: 9px 0;
  .avatar-chat {
    width: 36px;
    height: 36px;
  }
  .message-chats {
    .mix-text {
      .MuiAvatar-root {
        width: 242px;
        height: auto;
        border-radius: 3px;
      }
    }
    word-break: break-word;
    font-family: $primaryFont;
    font-size: 16px;
    line-height: 21px;
    color: $color-white;
    .wysiwyg-mention,
    a {
      color: $color-white;
      text-decoration: underline;
    }
    .forwarded-message {
      display: flex;
      svg {
        width: 14px;
        height: 14px;
        align-self: center;
        fill: $color-pearl;
      }
      // .message-forward-text{
      //   font-family: $primaryFont;
      //   font-size: 16px;
      //   line-height: 21px;
      //   color: $color-pearl;
      //   text-transform: lowercase;
      // }
      .message-text {
        font-family: $primaryFont;
        font-size: 16px;
        line-height: 21px;
        color: $color-white;
        padding-left: 20px;
      }
    }

    .time-chat {
      font-family: $primaryFont;
      font-size: 12px;
      line-height: 16px;
      color: $color-white;
    }
    .messages-user-name {
      font-family: $primaryFont;
      font-size: 16px;
      line-height: 21px;
      color: $color-check;
    }
    .message-reply-sec {
      display: flex;
      justify-content: space-between;
      background-color: $color-text-deco;
      padding: 5px;
      border-radius: 5px;
      margin-bottom: 5px;
      .message-reply-left-sec {
        .message-type {
          font-family: $primaryFont;
          font-size: 16px;
          line-height: 21px;
          color: $color-msg-left;
          text-transform: capitalize;
        }
      }
      .reply-image {
        margin-left: 5px;
      }
    }
  }
  .message-doc-view {
    .doc-link {
      text-transform: uppercase;
      display: flex;
      color: $color-white;
      text-decoration: none;
    }
    .doc-name {
      margin: 0;
      padding-left: 15px;
    }
  }
  .message-img-sec {
    max-width: 242px;
    // height: 192px;
    border-radius: $bRadius;
    margin-bottom: 3px;
    img {
      max-width: 100% !important;
      max-height: 100%;
    }
    .h-100 {
      max-height: 100%;
    }
  }
  .message-three-dots {
    align-self: center;
    padding: 0;
    .svg-three-dots {
      width: 10px;
      height: 20px;
      cursor: pointer;
      path {
        fill: $color-white;
      }
    }
  }
  .chat-left-person {
    display: flex;
    column-gap: 9px;
    margin: 12px 0;
    .message-chats {
      padding: 9px 9px 9px 20px;
      border-radius: 5px;
      background-color: $color-msg-left;

      .left-message-text {
        text-align: left;
      }
      .left-time-chat {
        text-align: right;
      }
      .react-player.audio-player {
        audio {
          width: revert !important;
        }
      }
      .audio-player {
        height: 40px !important;
      }
    }
  }
  .chat-right-person {
    display: flex;
    column-gap: 9px;
    justify-content: flex-end;
    margin: 12px 0 5px;

    // .message-rf-box-sec {
    //   display: flex;

    //   .ploygon-svg-sec {
    //     align-self: center;
    //     margin-left: -10px;
    //   }
    // }

    .message-chats {
      padding: 9px 9px 16px 20px;
      border-radius: 5px;
      background-color: $color-check;

      .right-message-text {
        text-align: right;
      }
      .right-time-chat {
        text-align: right;
      }
      .react-player.audio-player {
        audio {
          width: revert !important;
        }
      }
      .audio-player {
        height: 40px !important;
      }
    }
  }
}
.message-remove-forword-sec {
  background-color: $color-white;
  padding: 4px;
  border-radius: $bRadius;
  box-sizing: border-box;
  height: min-content;
  .message-rf-text {
    font-family: $primaryFont;
    font-size: 12px;
    line-height: 18px;
    color: $color-dark-black;
    cursor: pointer;
  }
}
