@import '../../../../../assets/scss/variable.scss';

.disciplines-techniques-skills-sec{
    background-color: $color-light-black;
    padding: 18px;
    .dts-left-sec{
        border-right: $blockBorder;
        .update-details-sec{
            padding-bottom: 18px;
            .update-details-header{
                font-family:$primaryFont;
                font-size: 15px;
                line-height: 20.8px;
                color: $color-check;
            }
            .update-details-data{
                font-family:$primaryFont;
                font-size: 16px;
                line-height: 20.8px;
                color: $color-white;
                padding-bottom: 2px;
            }
        }
        .update-details-sec:last-child{
            padding-bottom: 0;
        }
    }
    .dts-right-sec{
        padding-left: 18px;
        .update-details-right-sec{
            padding-bottom: 14px;
            padding-top: 16px;
            .update-details-right-data{
                display: flex;
                padding-bottom: 2px;
                
            .endorse-btn{
                background-color: $color-blue;
                font-family:$primaryFont;
                font-size: 12px;
                line-height: 16px;
                color: $color-white;
                width: 21px;
                height: 21px;
            }
            .endorse-text{
                font-family:$primaryFont;
                font-size: 12px;
                line-height: 16px;
                align-self: center;
                color: $color-white;
                margin: 0px 18px 0px 6px;
            }
            @media (max-width: 599px) {
                .endorse-text{
                    margin: 0px 6px 0px 6px;
                }
            }
            .endorse-addicon{
                padding: 0;
            }
        }
        
        }
        .update-details-right-sec:last-child{
            padding-bottom: 0;
        }
    }
    @media (max-width: 599px) {
        .dts-right-sec{
            padding-left: 6px;
        }
    }
}