@import '../../../../../assets/scss/variable.scss';

.acc_tab {
  position: relative !important;
  background-color: #202020 !important;
  color: $color-white !important;
  box-shadow: none !important;
  border-bottom: 1px solid $color-lava;
  border-radius: 0 !important;

  .MuiAccordionSummary-root {
    padding: 0;
  }
  .MuiAccordionSummary-content {
    margin: 20px 0 20px;
    .MuiTypography-body1 {
      font-size: 21.33px;
      font-weight: 500;
      line-height: 28px;
      font-family: $primaryFont;
    }
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0 0px;
  }
  .MuiAccordionDetails-root {
    padding: 5px 0 28px 0;
    .MuiTypography-body1 {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      font-family: $primaryFont;
    }
  }
}
.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
