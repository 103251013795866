@import '../../../../../assets/scss/variable.scss';

.discipline-rank-sec{
    display: flex;
    overflow-x: scroll;
    width: 100%;
    &::-webkit-scrollbar {
        height: 6px;
    }
    
    &::-webkit-scrollbar-track {
        background-color: #231f20;
        border-radius: $bRadius;
    }
    
    &::-webkit-scrollbar-thumb {
        background: $color-blue;
        border-radius: $bRadius;
    }
    
    .discipline-rank-data{
        min-width: 292px;
        margin-left: 9px;
        background-color: $color-light-black;
        .cover-img-sec{
            .cover-img{
                width: 100%;
                height: 71px;
            }
        }
            .profile-img{
                width: 63px;
                height: 63px;
                margin: -32px auto 0;
            }
    
    .game-name{
        font-family: $authHeaderFont;
        font-size: 21.33px;
        line-height: 26px;
        color: $color-white;
        text-align: center;
        padding: 20px 0;
    }
    .updated-discipline-rank{
        text-align: center;
        margin: 18px 0;
        .discpline-rank-title{
            font-family:$primaryFont;
            font-size: 15px;
            line-height: 20.8px;
            color: $color-check;
        }
        .discpline-rank-data{
            font-family:$primaryFont;
            font-size: 16px;
            line-height: 20.8px;
            color: $color-white;
        }
    }
    .endorsed-btn-sec{
        padding: 0 18px 18px;
        .endorsed-btn{
        background-color: $color-check;
        border-radius: $bRadius;
        color: $color-white;
        text-transform: none;
        font-family:$primaryFont;
        font-size: 12px;
        line-height: 18px;
        }
        .endorsed-icon{
            margin-right: 5px;
        }
    }
    }
}