@import '../../../../../../assets/scss/variable.scss';

.edit-social-block {
  .social-url {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
    word-break: break-word;
  }
  .age-field {
    img,
    svg {
      margin-right: 20px;
    }
  }
  @media (max-width: 599px) {
    .date-birth-field {
      display: block !important;
      padding: 0px 12px !important;
      .date-field {
        padding: 4.5px 0px !important;
      }
      .age-field {
        padding: 4.5px 0px !important;
        img,
        svg {
          width: 28px;
          margin-right: 10px;
        }
      }
    }
  }
}
