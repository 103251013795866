@import '../../../../../../assets/scss/variable.scss';

.community-page{
    position: relative;
    .mobile-view-title{
        display: flex;
        justify-content: space-between;
        h1{
            align-self: center;
            margin-bottom: 0;
        }
        .filter-btn{
            font-size: 14px;
            line-height: 19px;
            font-family: $primaryFont;
            color: $color-white;
            text-transform: capitalize;
        }
        .filter-sticky-btn{
            position: sticky;
            bottom: 70px;
            width: 100% !important;
        }
    }
    .mobile-view-filter-title{
        display: flex;
        h1{
            align-self: center;
            width: 100%;
            margin-bottom: 0;
        }
    }
    .mobile-view-filter-options{
        height: 60vh;
        .mobile-filter{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: $blockBorder;
            .filter-title{
                font-size: 16px;
                line-height: 21px;
                font-family: $primaryFont;
                color: $color-white;
            }
            .filter-right{
                display: flex;
                align-items: center;
                .circle-blue{
                    width: 21px;
                    height: 21px;
                    font-size: 12px !important;
                    line-height: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $color-blue;
                    color: $color-white;
                }
            }
        }
    }
    .filter-sec{
        height: 60vh;
        overflow: scroll;
        .filter-name-sec{
            padding-top: 0;
            padding-bottom: 0;
            .filter-check-box{
                padding: 0 9px;
                color:$color-white;
            }
            .filter-name{
                color: $color-white;
            }
        }
    }
    .filter-position{
        position: relative;
        .filter-button{
            .filter-btn{
                background: $color-blue;
                color: $color-white;
                text-transform: capitalize;
            }            
        }
        .filter-sticky-btn{
            position: sticky;
            bottom: 70px;
            width: 100% !important;
        }
    }
    
}