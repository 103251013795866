@import '../../../../../../assets/scss/variable.scss';

//   Badge Container
.badge-container {
  width: 100%;
  padding: 18px;
  margin-top: 9px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  @media (max-width: 599px) {
    padding: 12px;
  }
  .badge-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    font-family: $authHeaderFont;
    color: $color-white;
    margin-bottom: 14px;

    .my-top-badges-btn {
      padding: 0;
      font-size: 16px;
      line-height: 20px;
      font-family: $authHeaderFont;
      color: $color-white;
      text-decoration: underline;
      background-color: transparent;
    }
  }

  .badges-container {
    display: flex;
    .badge-block {
      max-width: 45px;
      height: auto;
      text-align: center;

      .badge-icon {
        width: 45px;
        height: 45px;
      }

      .verified-badge-text {
        margin-top: 4px;
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
        word-break: break-word;
      }
      .text-omision {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .no-badge-txt {
    width: 100%;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    text-align: center;
    margin-bottom: 7px;
    color: $color-spanish-gray;
  }
}
