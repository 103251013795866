@import '../../../../assets/scss/variable.scss';

.connection-page {
  .connection-page-title {
    font-size: 37.92px;
    line-height: 47px;
    color: $color-white;
    font-family: $authHeaderFont;
    text-align: center;
    margin-bottom: 19.5px;
  }

  .usercard-skeleton {
    min-height: 200px;
    width: 100%;
  }

  .connection-user-list {
    position: relative;
  }
  .visit-button {
    background-color: $color-blue;
    color: $color-white;
  }
}
.mt-20 {
  margin-top: 20px;
}
.m-auto {
  margin: 0 auto !important;
}
