@import '../../../../../assets/scss/variable.scss';

.profile-details-form {
  position: relative;
  margin-top: 9px;
  @media (max-width: 1279px) {
    margin-top: 9px;
  }

  .profile-picture-box {
    display: flex;
    align-items: flex-end;
    margin-bottom: 27px;
    @media (max-width: 1279px) {
      // display: flex;
      justify-content: center;
    } 

    @media (max-width: $mobileBreakPoint) {
      justify-content: flex-start;
    }
    .MuiIconButton-root {
      padding: 0;
      margin-right: 16px;
      width: 115px;
      height: 115px;
    }

    .MuiAvatar-root {
      width: 115px;
      height: 115px;
    }
    .camera-icon {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }

    .profile-pic-recomandations {
      position: relative;
      @media (max-width: 599px) {
        margin-top: 30px;
      }

      .rec-heding {
        font-size: 12px;
        line-height: 18px;
        font-weight: 700;
        font-family: $primaryFont;
      }
      .rec-types {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        font-family: $primaryFont;
      }
    }
  }

  .form-content {
    @media (max-width: 1279px) {
      margin-top: 10px;
    }
  }
  .profile-details-title {
    position: relative;
    font-size: 37.92px;
    line-height: 47px;
    font-family: $authHeaderFont;
    text-transform: uppercase;
    padding-bottom: 32px;

    @media (max-width: 1279px) {
      padding-bottom: 8px;
    }

    @media (max-width: 599px) {
      font-size: 30.92px;
    }
  }

  .prof-details-warn-text {
    position: relative;
    margin-top: 6px;
    margin-bottom: -2px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: $color-white;
    font-family: $primaryFont;
  }

  .profile-details-btn {

  }

  .profile-btn {
    position: relative;
    color: $color-black-very-light;
    background-color: $color-white;
    border-radius: $bRadius;
    font-size: 21.33px;
    line-height: 26px;
    font-family: $authHeaderFont;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
