@import '../../../../../../../assets/scss/variable.scss';

.event-fight-card-side-sec{
    text-align: center;
    .side-text{
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-blue;
        margin-bottom: 8px;
    }
    .v-sec{
        // width: 100%; 
        text-align: center; 
        border-bottom: 1px solid $color-pearl; 
        line-height: 0.1em;
        margin: 30px auto;
        max-width: 50%;
        .v-text{
            font-size: 12px;
            // line-height: 16px;
            font-weight: 400;
            font-family: $primaryFont;
            color: $color-pearl;
            background:$blockColor; 
            padding:0 10px; 
        }
    }
    .event-side-sec{
        display: flex;
        justify-content: center;
        .event-fight-profile-sec{
            text-align: center;
            min-width: 200px;
            .event-fight-profile{
                width: 64px;
                height: 64px;
                margin: 0 auto;
            }
            .event-user-data{
                margin-top: 9px;
                .event-user-name{
                    font-size: 28.45px;
                    line-height: 28.45px;
                    font-weight: 400;
                    font-family: $authHeaderFont;
                    color: $color-white;
                }
                .event-user-status{
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                    font-family: $primaryFont;
                    color: $color-pearl;
                }
            }
        }
    }
  
   
}
.event-1v1-sec{
    display: flex;
    justify-content: space-between;
    .event-fight-profile{
        width: 90px;
        height: 90px;
    }
    .event-user-data{
        .event-user-name{
            font-size: 28.45px;
            line-height: 28.45px;
            font-weight: 400;
            font-family: $authHeaderFont;
            color: $color-white;
        }
        .event-user-status{
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            font-family: $primaryFont;
            color: $color-pearl;
        }
    }
    .event-fight-record-left-sec{
        display: flex;
        align-items: center;
        .event-user-data{
            margin-left: 36px;
            text-align: left;
        }
    }
    .event-fight-record-center-sec{
        text-align: center;
        align-self: center;
        .event-reaction-data{
            margin-bottom: 8px;
        }
        .weight-text{
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            font-family: $primaryFont;
            color: $color-pearl;
            margin-bottom: 8px;
        }
        .result-link{
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            font-family: $primaryFont;
            color: $color-blue;
            text-decoration: underline;
            margin-bottom: 8px;
            cursor: pointer;
        }
    }
    .event-fight-record-right-sec{
        display: flex;
        align-items: center;
        .event-user-data{
            margin-right: 36px;
            text-align: right;
        }
    }
}