@import '../../../../../assets/scss/variable.scss';

.confirm-form {
  position: relative;

  .confirm-profile-box {
    margin-top: 4.5px;
    // display: flex;

    .confirm-left {
      margin-top: 10px;
      margin-bottom: 18px;
      .profile-img {
        position: relative;
        border-radius: 50%;

        .MuiIconButton-root {
          padding: 0;
        }
        .MuiAvatar-root {
          width: 115px;
          height: 115px;
        }
        .camera-icon {
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }

    .confirm-right {
      .confirmation-title-box {
        position: relative;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $color-white;
        margin-bottom: 18px;

        .conf-title {
          font-size: 28.45px;
          line-height: 36px;
          font-family: $authHeaderFont;
          text-transform: uppercase;
          color: $color-white;
          @media (max-width: 599px) {
            font-size: 24px;
          }
        }
        .MuiIconButton-root {
          padding-right: 2px;
          padding: 0;
        }
      }

      .conf-field-name {
        font-size: 21.33px;
        line-height: 26px;
        margin-bottom: 3px;
        font-family: $authHeaderFont;
        color: $color-white;
        @media (max-width: 599px) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      .conf-user-name {
        position: relative;
        font-size: 16px;
        line-height: 21px;
        font-family: $primaryFont;
        color: $color-white;
        margin-top: 3px;
        margin-bottom: 18px;
      }

      .checkbox-field {
        position: relative;
        margin-bottom: 18px;
        @media (max-width: 599px) {
          margin-bottom: 14px;
        }
        .email-mobile-text {
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          font-family: $primaryFont;
        }
      }
      .address-box {
        margin-bottom: 18px;
        .conf-add-field {
          font-size: 16px;
          line-height: 21px;
          font-family: $primaryFont;
          color: $color-white;
        }
      }

      .profile-types {
        margin-top: 1px;

        .conf-prof-type {
          font-size: 16px;
          line-height: 21px;
          font-family: $primaryFont;
          color: $color-white;
        }
      }
    }
  }

  .prof-type-head {
    margin-top: 36px;
    @media (max-width: 599px) {
      margin-top: 24px;
    }
  }

  .confirm-submit-box {
    position: relative;
    margin-top: 45px;

    .confirm-btn {
      background-color: $color-white;
      font-size: 21.33px;
      line-height: 26px;
      color: #000;
      font-family: $authHeaderFont;
      // padding-top: 15.5px;
      // padding-bottom: 15.5px;
      padding-top: 14px;
      padding-bottom: 14px;
      border-radius: $bRadius;
      @media (max-width: 599px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
