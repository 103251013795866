@import '../../../../../assets/scss/variable.scss';

// hot activity record
.hotactivity-container {
  position: relative;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  padding: 18px;
  .record-title {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 20px;
    font-family: $authHeaderFont;
    color: $color-white;
    margin-bottom: 9px;
  }
  .see-all-btn-right {
    padding: 0;
    font-size: 16px;
    line-height: 20px;
    color: $color-blue;
    font-family: Staatliches;
    background: transparent;
    min-width: 0;
  }
  .add-record-btn {
    width: 100%;
    border-radius: $bRadius;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    background-color: $color-blue;
    color: $color-white;
    margin-bottom: 5px;
  }
}
