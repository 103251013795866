@import '../../../../../../assets/scss/variable.scss';

// CREATE NEW POST
.creating-post-update-box {
  padding: 18px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  margin-bottom: 9px;

  // USER DETAILS
  .user-details {
    display: flex;
    align-items: center;
    .MuiAvatar-root {
      width: 45px;
      height: 45px;
    }

    .user-name {
      margin-left: 9px;
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      font-family: $primaryFont;
      color: 0;
    }
  }
  .custome-text-editior {
    .rdw-suggestion-dropdown {
      color: $color-black;
      min-width: 200px;
    }
    .rdw-mention-link {
      background: transparent;
      color: $color-blue;
    }
    .editior-error-message {
      font-family: $primaryFont;
      color: $color-pin;
    }
    .rdw-editor-toolbar {
      background: none;
      border: $blockBorder;
      .rdw-option-wrapper {
        background: none;
        border: none;
      }
      .rdw-option-wrapper:hover {
        box-shadow: none;
      }
      .rdw-option-active {
        box-shadow: none;
        border: $blockBorder;
      }
    }
    .rdw-editor-main {
      border: $blockBorder;
      .public-DraftStyleDefault-ltr {
        font-family: $primaryFont;
        margin: 12px 10px;
      }
      .public-DraftEditorPlaceholder-root {
        font-family: $primaryFont;
      }
      .public-DraftEditorPlaceholder-inner {
        margin: 0px 10px;
      }
    }
    .demo-editor {
      min-height: 180px !important;
    }
    .rdw-image-modal {
      background: $color-black;
      font-family: $primaryFont;
      border: $blockBorder;
      box-shadow: none;
      height: auto;
      .rdw-image-modal-url-input {
        margin-bottom: 5px;
        height: 28px;
      }
    }
    .rdw-link-modal {
      background: $color-black;
      font-family: $primaryFont;
      border: $blockBorder;
      box-shadow: none;
      height: auto;
      .rdw-link-modal-input {
        margin-bottom: 5px;
        height: 28px;
      }
    }
  }
  // CREATE POST DETAILS BOX
  .post-title-input {
    margin-top: 18px;

    .field-character-count {
      float: right;
      margin-right: -14px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
    }
    
    .cp-restriction-box {
      .restriction-txt {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
      }
      .restriction-chips {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        &::-webkit-scrollbar {
          display: none;
        }
        .rest-chip {
          height: 21px;
          margin-top: 9px;
          margin-right: 3px;
          color: #9d9d9d;
          background-color: #3d3e3f;
          display: flex;
          align-items: center;
          @media (max-width: 599px) {
            margin-right: 9px;
          }
          .MuiChip-iconSmall {
            width: 13px;
            height: 13px;
          }
          .MuiChip-labelSmall {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            font-family: $primaryFont;
            position: relative;
            top: -1px;
          }
        }
        .checked-chip {
          background-color: $color-blue !important;
          color: $color-white !important;
        }
      }
    }
  }
  .cp-cnt {
    margin-top: 8.5px;
  }

  .select-posting-type {
    .MuiListItemIcon-root {
      min-width: 0;
      margin-right: 0px;
    }

    .type-text-block {
      display: flex;
      justify-content: space-between;
      max-width: inherit;

      @media (max-width: 599px) {
        display: block;
      }

      .label-name {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
      }

      .label-name {
        @media (max-width: 599px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .MuiTouchRipple-root {
      display: none;
    }

    .MuiList-padding,
    .MuiListItem-dense {
      padding-top: 0;
      padding-bottom: 0;
    }
    .MuiListItem-gutters {
      padding-left: 4px;
    }

    .MuiIconButton-colorSecondary:hover,
    .MuiCheckbox-colorSecondary.Mui-checked:hover {
      background-color: transparent;
    }
  }
}

.form-btn {
  display: flex;
  .create-post-btn {
    padding: 0;
    height: 54px;
    font-size: 21.33px;
    line-height: 26px;
    font-family: $authHeaderFont;
    background-color: $color-blue;
    color: $color-white;
    &:hover {
      background-color: $color-blue;
      color: $color-white;
    }
    &:disabled {
      background-color: $color-fb;
    }
  }
  .cancel-btn {
    margin-right: 10px;
    background-color: $color-gray-dark;
  }
}
