@import '../../../../assets/scss/variable.scss';

.request-block {
  display: flex;
  align-items: center;

  .MuiButton-root {
    width: 80px;
    height: 35px;
    padding: 0;
    font-size: 16px;
    line-height: 20px;
    font-family: $authHeaderFont;
    color: $color-white;
  }

  .MuiButton-outlined {
    color: $color-white;
    border: 1px solid $color-white;
  }
  .MuiButton-contained {
    background: $color-blue;
    &:hover {
      background: $color-blue;
    }
  }
}
