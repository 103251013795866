@import '../../../../../assets/scss/variable.scss';
.profile-types-form {
  .default-fan-check {
    position: relative;

    .MuiList-padding {
      padding-top: 0;
    }
    .MuiListItem-root {
      display: flex;
      align-items: flex-start;
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 12px;
      border-bottom: 1px solid #3d3e3f;
      cursor: inherit;
    }

    .MuiListItemText-secondary {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      color: #9d9d9d;
    }
  }

  // New Org-setup-checkbox
  .org-setup-check {
    position: relative;
    .MuiList-root {
      padding-top: 16px;
    }
  }

  // select other profile types
  .select-other-types {
    position: relative;
    margin-top: 19px;
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
  }

  .profile-type-checks {
    position: relative;

    .other-types-ul {
      padding-bottom: 19px;
      border-bottom: 1px solid #3d3e3f;
    }
    .MuiTouchRipple-root {
      display: none;
    }

    .MuiListItem-dense {
      @media (max-width: 599px) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .MuiListItem-gutters {
      padding-left: 0px;
    }

    .MuiCheckbox-root {
      color: $color-white;
    }

    .MuiIconButton-colorSecondary:hover,
    .MuiCheckbox-colorSecondary.Mui-checked:hover {
      background-color: transparent;
    }

    .MuiListItemIcon-root {
      min-width: 0;
      margin-right: 19px;
    }

    .MuiListItemText-primary {
      font-size: 21.33px;
      line-height: 23px;
      font-family: Roboto;
      font-weight: 400;
    }
  }

  .profile-type-actions {
    margin-top: 26px;

    .profile-type-sbmtbtn {
      width: 49%;
      background-color: $color-white;
      font-size: 21.33px;
      line-height: 23px;
      font-family: $authHeaderFont;
      border-radius: $bRadius;
      padding-top: 15.5px;
      padding-bottom: 15.5px;
      @media (max-width: 599px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
