@import '../../../../assets/scss/variable.scss';

.see-all-activity-page{
    background-color: $blockColor;
    border: $blockBorder;
    padding: 18px;
    .back-to-profile {
        padding-bottom: 26px;
        .back-text {
          padding: 0;
          font-size: 21.33px;
          font-family: $authHeaderFont;
          color: $color-white;
          @media (max-width: 599px) {
            font-size: 16px;
            padding: 8px 0;
          }
        }
        @media (max-width: 599px) {
          padding-bottom: 10px;
        }
    }
    .activity-title{
        font-family: $authHeaderFont;
        font-size: 28.45px;
        line-height: 36px;
        color: $color-white;
    }
}