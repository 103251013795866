@import '../../../../assets/scss/variable.scss';

.connection-user {
  .MuiButton-contained.Mui-disabled {
    color: $color-white;
    background-color: $color-gray-dark;
  }
  .connection-send-btn {
    margin-top: 9px;
    padding: 6px 16px;
    color: #d9e8f3;
    background-color: $color-blue;
    font-size: 12px;
    line-height: 18px;
    font-family: $primaryFont;
    text-transform: capitalize;
    border-radius: $bRadius;
  }

  .user-f-btn {
    padding: 7px 0px;

    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    text-transform: capitalize;
    background-color: $color-blue;
    color: #d9e8f3;
    border-radius: $bRadius;

    &:hover {
      background-color: $color-blue;
      color: #d9e8f3;
    }
  }

  .connection-have-btn {
    margin-top: 9px;
    padding: 7px 0px;
    color: $color-white;
    background-color: $color-gray-dark;
    font-size: 12px;
    line-height: 18px;
    font-family: $primaryFont;
    text-transform: capitalize;
    border-radius: $bRadius;
    @media (max-width: 599px) {
      padding: 6px 0px;
    }
    &:hover {
      color: $color-white;
      background-color: $color-gray-dark;
    }
  }
  .profile-following-btn {
    padding: 7px 0px;
    color: $color-white !important;
    background-color: $color-gray-dark !important;
    font-size: 12px;
    line-height: 18px;
    font-family: $primaryFont;
    text-transform: capitalize;
    border-radius: $bRadius;

    &:hover {
      background-color: $color-gray-dark;
    }
  }
  // .tool-tip-conainer {
  //   .MuiTooltip-tooltip {
  //     background: $color-white;
  //     color: $color-black;
  //     // padding: 0;
  //     .MuiListItem-gutters {
  //       padding: 0;
  //       margin-bottom: 4px;
  //     }
  //     .MuiTooltip-arrow::before {
  //       background-color: $color-white;
  //     }
  //   }
  // }
  .profile-btns-container {
    @media (max-width:$mobileBreakPoint) {
      display: flex;
      justify-content: space-between;
      .profile-following-btn {
        width: 48%;
      }
      .user-f-btn {
        width: 48%;
      }
      .connection-have-btn {
        width: 48%!important;
        margin-top: 0;
      }
      .mobile-btn-group {
        width: 48%;
      }
    }
  }
  height: 100%;
  &:hover {
    box-shadow: -2px 5px 12px #e6e1e133;
  }
  width: 100%;
  position: relative;
  padding: 18px;
  background-color: $color-lime-black;
  border: $blockBorder;
  border-radius: $bRadius;
  @media (min-width: 600px) and (max-width: 799px) {
    padding: 12px;
  }
  @media (max-width: 599px) {
    display: block;
    padding: 12px;
  }
  .bio-description {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    word-break: break-word;
  }
  .bio-head {
    font-family: 'Staatliches';
    font-size: 16px;
  }
  .readmore-btn {
    padding: 0;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    text-transform: inherit;
    color: $color-blue;
  }
  .pt-14 {
    padding-top: 14px;
  }
  .user-image {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 9px 0px 18px 0;
    cursor: pointer;
    @media (min-width: 600px) and (max-width: 799px) {
      margin: 0px 0px 18px 0;
    }
    @media (max-width: 599px) {
      display: block;
      margin: 0px 0px 18px 0;
      text-align: center;
    }
    @media (max-width: $mobileBreakPoint) {
      display: flex;
      justify-content: flex-start;
    }
    .MuiAvatar-root {
      width: 115px;
      height: 115px;
      @media (min-width: 600px) and (max-width: 799px) {
        width: 80px;
        height: 80px;
      }
      @media (max-width: 599px) {
        margin: 9px auto;
      }
      @media (max-width: $mobileBreakPoint) {
        margin: 0;
      }
    }
  }

  .user-name {
    margin-top: 18px;
    padding-left: 18px;
    @media (min-width: 600px) and (max-width: 799px) {
      padding-left: 12px;
    }
    .username-icon{
      display: flex;
      .user-icon{
        padding: 0 12px;
      }
    }
    .name {
      font-size: 28.45px;
      line-height: 26px;
      margin-bottom: 4px;
      font-family: $authHeaderFont;
      color: $color-white;
      text-align: left;
      word-break: break-word;
      cursor: pointer;
      @media (min-width: 600px) and (max-width: 799px) {
        font-size: 24px;
      }
      @media (max-width: 599px) {
        text-align: center;
        font-size: 26px;
      }
      @media (max-width: $mobileBreakPoint) {
        text-align:initial;
      }
    }
    .user-dob {
      font-family: Staatliches;
      font-size: 12px;
      color: #b4babf;
    }
    .social-icons {
      margin-top: 3px;
      @media (max-width: 599px) {
        .MuiGrid-root {
          justify-content: center;
        }
      }

      .MuiGrid-root {
        justify-content: flex-start;
      }
    }
  }
  .border-gray {
    border-bottom: $blockBorder;
    padding-bottom: 18px;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .visit-button {
    width: 49%;
    background-color: $color-blue !important;
    color: $color-white !important;
    padding: 0;
    height: 30px;
    font-size: 12px;
    line-height: 16px;
    font-family: $primaryFont;
    font-weight: 400;
    text-transform: capitalize;
    &:hover {
      background-color: $color-blue;
      color: $color-white;
    }
  }
  .social-link {
    a {
      margin-right: 4px;
      img {
        max-width: 18px;
        max-height: 18px;
      }
    }
  }
  .right-prof-counts {
    position: relative;
    display: flex;
    justify-content: space-around;
    margin-top: 9px;
    border-bottom: $blockBorder;
    padding-bottom: 9px;
    .social-count-bx {
      text-align: center;
      .social-count {
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        font-family: $primaryFont;
        color: $color-blue;
      }
      .social-act-name {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        font-family: $primaryFont;
        color: $color-white;
      }
    }
  }
}
.message-menu {
  padding: 10px 15px !important;
  font-family: Roboto;
  font-size: 12px;
  li {
    padding: 0;
    margin-bottom: 4px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
