@import '../../../../../assets/scss/variable.scss';

.my-profile-right {
  width: 100%;

  .skel-edit-ps {
    height: 30px;
    border-radius: $bRadius;
  }

  .my-right-top {
    padding: 18px;
    width: 100%;
    background: $blockColor;
    border: $blockBorder;
    border-radius: $bRadius;
    @media (max-width: 599px) {
      padding: 12px;
    }

    .readmore-btn {
      padding: 0;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: Roboto;
      text-transform: inherit;
      color: #438ac1;
    }

    .my-right-img {
      width: 100%;
      display: flex;
      @media (max-width: 599px) {
        display: block;
      }
      .my-right-avatar {
        .right-img {
          // position: relative;
          // border-radius: 50%;
          @media (max-width: 599px) {
            text-align: center;
            margin-bottom: 14px;
            display: flex;
            justify-content: center;
          }
          .MuiIconButton-root {
            padding: 0;
          }
          .MuiAvatar-root {
            width: 115px;
            height: 115px;
          }
          .camera-icon {
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }

      .myright-names {
        padding-left: 25px;
        @media (max-width: 599px) {
          padding-left: 0;
          text-align: center;
        }
        .myright-wildcard {
          font-size: 16px;
          line-height: 20px;
          font-family: $authHeaderFont;
          color: $color-blue;
          margin-top: 7px;
          word-break: break-word;
          @media (max-width: 599px) {
            font-size: 14px;
            line-height: 18px;
            text-align: center;
          }
        }
        .right-username {
          font-size: 30px;
          line-height: 38px;
          font-family: $authHeaderFont;
          color: $color-white;
          margin-bottom: 4px;
          word-break: break-word;
          @media (max-width: 599px) {
            font-size: 26px;
            line-height: 32px;
            text-align: center;
          }
        }

        .member-since {
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 15px;
          font-family: $authHeaderFont;
          color: #adbdc2;
          @media (max-width: 599px) {
            justify-content: center;
          }
          .logout-btn {
            padding: 0;
            min-width: 0;
            margin-left: 20px;
            color: $color-blue;
            font-size: 16px;
            line-height: 16px;
            font-family: $authHeaderFont;
            background-color: transparent;
          }
        }

        .social-icons {
          display: flex;
          margin-top: 9px;
          .MuiGrid-container {
            @media (max-width: 599px) {
              justify-content: center;
            }
          }
          .social-link {
            margin-right: 6px;
            img {
              max-width: 18px;
              max-height: 18px;
            }
            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }

    .profile-follow-btn {
      margin-top: 18px;
      padding: 6px 16px;
      color: $color-light-blue;
      background-color: $color-blue;
      font-size: 12px;
      line-height: 18px;
      font-family: $primaryFont;
      text-transform: capitalize;
      border-radius: $bRadius;
      width: 100%;
    }

    .profile-following-btn {
      margin-top: 18px;
      padding: 6px 16px;
      color: $color-white;
      background-color: $color-gray-dark;
      font-size: 12px;
      line-height: 18px;
      font-family: $primaryFont;
      text-transform: capitalize;
      border-radius: $bRadius;
      width: 100%;
    }

    .connection-send-btn {
      margin-top: 9px;
      padding: 6px 16px;
      color: #d9e8f3;
      background-color: $color-blue;
      font-size: 12px;
      line-height: 18px;
      font-family: $primaryFont;
      text-transform: capitalize;
      border-radius: $bRadius;
    }

    .connection-have-btn {
      margin-top: 9px;
      padding: 6px 16px;
      color: $color-white;
      background-color: $color-gray-dark;
      font-size: 12px;
      line-height: 18px;
      font-family: $primaryFont;
      text-transform: capitalize;
      border-radius: $bRadius;
      @media (max-width: 599px) {
        padding: 6px 0px;
      }
    }

    .blue-button {
      background-color: $color-blue;
      color: #d9e8f3;
    }

    .social-right-edit-actions {
      position: relative;
      padding-top: 18px;
      display: flex;
      justify-content: space-between;

      .edt-scl-lnkbtn {
        color: $color-spanish-gray;
        background-color: $color-gray-dark;
        width: 49%;
        font-size: 12px;
        line-height: 18px;
        font-family: $primaryFont;
        text-transform: capitalize;
        border-radius: $bRadius;
        @media (max-width: 374px) {
          padding: 6px 9px;
        }
      }
      .edt-profile-btn {
        color: $color-light-blue;
        background-color: $color-blue;
        width: 49%;
        font-size: 12px;
        line-height: 18px;
        font-family: $primaryFont;
        text-transform: capitalize;
        border-radius: $bRadius;
        @media (max-width: 374px) {
          padding: 6px 9px;
        }
      }
    }

    .right-prof-counts {
      position: relative;
      display: flex;
      justify-content: space-around;
      padding: 9px 0;
      margin-top: 15px;
      border-top: $blockBorder;
      border-bottom: $blockBorder;

      .social-count-bx {
        text-align: center;
        .social-count {
          font-size: 16px;
          line-height: 21px;
          font-weight: 500;
          font-family: $primaryFont;
          color: $color-blue;
        }
        .social-act-name {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          font-family: $primaryFont;
          color: #6a6a6a;
        }
      }
    }

    .bio-block {
      padding-top: 14px;

      .bio-title {
        font-size: 16px;
        line-height: 20px;
        font-family: $authHeaderFont;
        color: $color-white;
        margin-bottom: 9px;
      }

      .bio-text {
        // font-size: 12px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
        word-break: break-word;
        // margin-bottom: 18px;
      }
      .no-info-txt {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        font-family: Roboto;
        color: #9d9d9d;
      }

      .edit-bio-btn {
        width: 100%;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        font-family: $primaryFont;
        text-transform: capitalize;
        background-color: $color-blue;
        color: $color-light-blue;
        border-radius: $bRadius;
      }
    }
  }
}
.field-character-count {
  float: right;
  margin-right: -14px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-family: $primaryFont;
  color: $color-white;
}

.profile-btn-group-container{
  @media (max-width:$mobileBreakPoint) {
      width: 49%;
      padding-top: 18px
  }
}