@import '../../../../../assets/scss/variable.scss';

.left-profile-container {
  position: relative;
  width: 100%;

  .cover-photo-container {
    .MuiButtonBase-root {
      width: 100%;
      padding: 0;
    }

    .preview-cover {
      position: relative;
      width: 100%;
      height: 308px;
      border-radius: $bRadius;

      @media (max-width: 599px) {
        height: 150px !important; // addmin has common class
      }
    }
    .camera-icon {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    .upload-button {
      width: 100%;
      border-radius: $bRadius;
      padding: 146px 0px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      font-family: $primaryFont;
      text-transform: capitalize;
      background-color: #1b1b1b;
      border: $blockBorder;
      color: #9d9d9d;
    }

    .MuiButton-endIcon {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
}
.react-viewer-close {
  z-index: 1111 !important;
}
.react-viewer-btn {
  background-color: $color-blue !important;
}
.infinity-scroll-view-custom {
  height: 600px;
  overflow: auto;
  display: flex;
  width: 100%;

  @media (max-width: $mobileBreakPoint) {
    height: 100%;
    overflow: visible;
  }
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-black-very-light;
    border-radius: $bRadius;
    padding: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: $color-blue;
    border-radius: $bRadius;
  }
}
// .infinity-scroll-view {
//   .infinite-scroll-component__outerdiv {
//     width: 100%;
//   }
// }
// .infinity-scroll-view::-webkit-scrollbar {
//   display: none;
// }
