@import '../../../../../assets/scss/variable.scss';

.myprofile-mobile {
  width: 100%;
}

.myprofile-mobile-container {
    width: 100%;
    height: 100%;
    // ORIGIN RIGHT PART
    // waiting skeleton part
    display: flex;
    flex-direction: column;
  
    .skel-edit-ps {
      height: 30px;
      border-radius: $bRadius;
    }
    // user avatar & middle btns & bio part
    .my-right-top {
      padding: 18px;
      width: 100%;
      background: $blockColor;
      border: $blockBorder;
      border-radius: $bRadius;
      order:2;
      @media (max-width: 599px) {
        padding: 12px;
      }
  
      .readmore-btn {
        padding: 0;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        font-family: Roboto;
        text-transform: inherit;
        color: #438ac1;
      }

      .my-right-img {
        width: 100%;
        display: flex;
        .my-right-avatar {
          .right-img {
            // position: relative;
            // border-radius: 50%;
            @media (max-width: 599px) {
              text-align: center;
              margin-bottom: 14px;
              display: flex;
              justify-content: center;
            }
            .MuiIconButton-root {
              padding: 0;
            }
            .MuiAvatar-root {
              width: 115px;
              height: 115px;
            }
            .camera-icon {
              position: absolute;
              right: 0;
              bottom: 0;
            }
          }
        }
  
        .myright-names {
          padding-left: 15px;
          .myright-wildcard {
            font-size: 14px;
            line-height: 18px;
            font-family: $authHeaderFont;
            color: $color-blue;
            margin-top: 7px;
            word-break: break-word;
          }
          .right-username {
            font-size: 26px;
            line-height: 32px;
            font-family: $authHeaderFont;
            color: $color-white;
            margin-bottom: 4px;
            word-break: break-word;
          }
  
          .member-since {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 15px;
            font-family: $authHeaderFont;
            color: #adbdc2;
  
            .logout-btn {
              padding: 0;
              min-width: 0;
              margin-left: 20px;
              color: $color-blue;
              font-size: 16px;
              line-height: 16px;
              font-family: $authHeaderFont;
              background-color: transparent;
            }
          }
  
          .social-icons {
            display: flex;
            margin-top: 9px;
            .social-link {
              margin-right: 6px;
              img {
                max-width: 18px;
                max-height: 18px;
              }
              &:last-child {
                margin-right: 0px;
              }
            }
          }
        }
      }

      .profile-relation-btns {
        @media(max-width:$mobileBreakPoint) {
          display: flex;
          justify-content: space-around;
        }
      }
  
      .profile-follow-btn {
        margin-top: 18px;
        padding: 6px 16px;
        color: $color-light-blue;
        background-color: $color-blue;
        font-size: 12px;
        line-height: 18px;
        font-family: $primaryFont;
        text-transform: capitalize;
        border-radius: $bRadius;
        width: 100%;
        @media (max-width: $mobileBreakPoint) {
          width: 49%;
        }
      }
  
      .profile-following-btn {
        margin-top: 18px;
        padding: 6px 16px;
        color: $color-white;
        background-color: $color-gray-dark;
        font-size: 12px;
        line-height: 18px;
        font-family: $primaryFont;
        text-transform: capitalize;
        border-radius: $bRadius;
        width: 100%;
        @media (max-width: $mobileBreakPoint) {
          width: 49%;
        }
      }
  
      .connection-send-btn {
        margin-top: 9px;
        padding: 6px 16px;
        color: #d9e8f3;
        background-color: $color-blue;
        font-size: 12px;
        line-height: 18px;
        font-family: $primaryFont;
        text-transform: capitalize;
        border-radius: $bRadius;
      }
  
      .connection-have-btn {
        margin-top: 9px;
        padding: 6px 16px;
        color: $color-white;
        background-color: $color-gray-dark;
        font-size: 12px;
        line-height: 18px;
        font-family: $primaryFont;
        text-transform: capitalize;
        border-radius: $bRadius;
        @media (max-width: 599px) {
          padding: 6px 0px;
        }
      }
  
      .blue-button {
        background-color: $color-blue;
        color: #d9e8f3;
      }
  
      .social-right-edit-actions {
        position: relative;
        padding-top: 18px;
        display: flex;
        justify-content: space-between;
  
        .edt-scl-lnkbtn {
          color: $color-spanish-gray;
          background-color: $color-gray-dark;
          width: 49%;
          font-size: 12px;
          line-height: 18px;
          font-family: $primaryFont;
          text-transform: capitalize;
          border-radius: $bRadius;
          @media (max-width: 374px) {
            padding: 6px 9px;
          }
        }
        .edt-profile-btn {
          color: $color-light-blue;
          background-color: $color-blue;
          width: 49%;
          font-size: 12px;
          line-height: 18px;
          font-family: $primaryFont;
          text-transform: capitalize;
          border-radius: $bRadius;
          @media (max-width: 374px) {
            padding: 6px 9px;
          }
        }
      }
  
      .right-prof-counts {
        position: relative;
        display: flex;
        justify-content: space-around;
        padding: 9px 0;
        margin-top: 15px;
        border-top: $blockBorder;
        border-bottom: $blockBorder;
  
        .social-count-bx {
          text-align: center;
          .social-count {
            font-size: 16px;
            line-height: 21px;
            font-weight: 500;
            font-family: $primaryFont;
            color: $color-blue;
          }
          .social-act-name {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            font-family: $primaryFont;
            color: #6a6a6a;
          }
        }
      }
  
      .bio-block {
        padding-top: 14px;

        .bio-title {
          font-size: 16px;
          line-height: 20px;
          font-family: $authHeaderFont;
          color: $color-white;
          margin-bottom: 9px;
        }
  
        .bio-text {
          // font-size: 12px;
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          font-family: $primaryFont;
          color: $color-white;
          word-break: break-word;
          // margin-bottom: 18px;
        }
        .no-info-txt {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          font-family: Roboto;
          color: #9d9d9d;
        }
  
        .edit-bio-btn {
          width: 100%;
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          font-family: $primaryFont;
          text-transform: capitalize;
          background-color: $color-blue;
          color: $color-light-blue;
          border-radius: $bRadius;
        }
      }
    }
  
    // ORIGIN LEFT PART
    // cover image container
    .cover-photo-container {
      order: 1;
      margin-bottom: 10px;
      margin-left: -16px;
      margin-right: -16px;
      .MuiButtonBase-root {
        width: 100%;
        padding: 0;
      }
  
      .preview-cover {
        position: relative;
        width: 100%;
        height: 308px;
        border-radius: $bRadius;
  
        @media (max-width: 599px) {
          height: 150px !important; // addmin has common class
        }
      }
      .camera-icon {
        position: absolute;
        top: 12px;
        right: 12px;
      }
  
      .upload-button {
        width: 100%;
        border-radius: $bRadius;
        padding: 146px 0px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        font-family: $primaryFont;
        text-transform: capitalize;
        background-color: #1b1b1b;
        border: $blockBorder;
        color: #9d9d9d;
      }
  
      .MuiButton-endIcon {
        position: absolute;
        top: 12px;
        right: 12px;
      }
    }
  
    // profile details contianre
    .left-profile-details-box {
      order:3;
    }
  
    // badges container {
    .badge-container {
      order:4;
    }
  
    // About tab Slider container
    .about-tab {
      position: relative;
      width: 100%;
      padding: 18px;
      background-color: $blockColor;
      border: $blockBorder;
      border-radius: $bRadius;
      margin-top: 9px;
      order: 5;
      @media (max-width: 599px) {
        padding: 12px !important; // addmin has common class
      }
    }
  
    // Fight record container
    .fight-record-container {
      order:6;
    }
    
    // Discipline tab container
    .discipline-tab {
      order:7;
      position: relative;
      width: 100%;
      padding: 18px;
      background-color: $blockColor;
      border: $blockBorder;
      border-radius: $bRadius;
      margin-top: 9px;
      @media (max-width: 599px) {
        padding: 12px !important; // addmin has common class
      }
    }
  
    // social activity container
    .social-activity-container {
      order:8;
    }
  
    // Endorsments container
    .skills-main {
      order:9;
    }
  
    // Recent activity container
    .recent-activity {
      order:10;
      margin-bottom: 10px;
    }
  
    .MuiIconButton-root {
      padding: 0;
      @media (max-width: 599px) {
        font-size: 1rem !important; // addmin has common class
      }
    }
    // profile about content text
    .profile-type-about-txt {
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
      text-align: left;
      margin-top: 9px;
      word-break: break-word;
      @media (max-width: 599px) {
        font-size: 14px !important; // addmin has common class
      }
    }
  
    .no-info-txt {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-spanish-gray;
      margin-top: 16px;
      margin-bottom: 7px;
      text-align: center;
    }
  
    .skel-prof-type-tab {
      width: 50px;
      height: 30px;
      border-radius: 3px;
      margin-right: 9px;
    }
  
    .title-block {
      display: flex;
      justify-content: space-between;
  
      .tab-block-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 21.33px;
        line-height: 26px;
        font-family: $authHeaderFont;
        color: $color-white;
        @media (max-width: 599px) {
          font-size: 14px !important; // addmin has common class
          line-height: 20px !important; // addmin has common class
        }
      }
    }
    .edit-about-btn {
      margin-top: 9px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  
    .edit-about-btn {
      margin-top: 9px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  
    .slider-container {
      position: relative;
      width: 100%;
      margin-top: 9px;
  
      .MuiTabs-root {
        min-height: 0;
      }
      .MuiTab-root {
        min-height: 0;
        padding: 5px 9px;
        border-radius: $bRadius;
        @media (min-width: 600px) {
          min-width: 0;
        }
      }
  
      .MuiTab-wrapper {
        font-size: 16px;
        line-height: 20px;
        font-family: $authHeaderFont;
        @media (min-width: 600px) {
          min-width: 0;
        }
        @media (max-width: 599px) {
          font-size: 14px !important; // addmin has common class
        }
      }
  
      .Mui-selected {
        background-color: $color-blue;
      }
  
      .MuiTabs-indicator {
        display: none;
      }
    }
  }
  

.user-profile-dialog {
    @media(max-width:$mobileBreakPoint) {
      .MuiDialog-paper {
        margin: 0%;
        position: absolute;
        top: 0;
        bottom:0;
        min-height: calc(100% - 32px);
        width: 100%;
        padding-top:0;
        overflow-x: hidden;
      }
  
      .dialog-content-box {
        position:sticky;
        top:0;
        padding-top:10px;
        z-index: 2;
        background-color: #171717;
        width: 100%;
        margin-bottom:10px;
      }
      .edit-profile, .edit-social-block {
        .dialog-submit-container {
          position:sticky;
          bottom:0;
          z-index: 2;
          background-color: #171717;
          margin-top: 10px;
          padding-top:0;
        }
        .dialog-submit-button {
          padding-bottom:25px;
          padding-top: 25px;
          margin-top: 0;
        }
      }
    }
  }

.mobile-drawer.take-upload-photo {
  
    .MuiDrawer-paperAnchorBottom {
      height: 30%;
      background-color: #171717;
      color:#fff;
      padding:0 16px;
    }
  }