@import '../../../../../assets/scss/variable.scss';

.post-name {
  cursor: pointer;
}
.user-action {
  margin: 0 10px;
  .profile-following-btn {
    margin-top: 18px;
    padding: 6px 16px;
    color: $color-white;
    background-color: $color-gray-dark;
    font-size: 12px;
    line-height: 18px;
    font-family: $primaryFont;
    text-transform: capitalize;
    border-radius: $bRadius;
    width: 100%;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .profile-follow-btn {
    margin-top: 18px;
    padding: 6px 16px;
    color: $color-light-blue;
    background-color: $color-blue;
    font-size: 12px;
    line-height: 18px;
    font-family: $primaryFont;
    text-transform: capitalize;
    border-radius: $bRadius;
    width: 100%;
  }
  .blue-button {
    background-color: $color-blue !important;
    color: #d9e8f3 !important;
  }
  .connection-send-btn {
    margin-top: 9px;
    padding: 6px 16px;
    color: #d9e8f3;
    background-color: $color-blue;
    font-size: 12px;
    line-height: 18px;
    font-family: $primaryFont;
    text-transform: capitalize;
    border-radius: $bRadius;
  }
  .connection-have-btn {
    margin-top: 9px;
    padding: 7px 0px;
    color: $color-white;
    background-color: $color-gray-dark;
    font-size: 12px;
    line-height: 18px;
    font-family: $primaryFont;
    text-transform: capitalize;
    border-radius: $bRadius;
    @media (max-width: 599px) {
      padding: 6px 0px;
    }
    &:hover {
      color: $color-white;
      background-color: $color-gray-dark;
    }
  }
}
