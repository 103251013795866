@import '../../../../../../../assets/scss/variable.scss';

.community-related-box {
  padding: 18px;
  background-color: $blockColor;
  border-radius: $bRadius;
  border: $blockBorder;
  .no-data-txt {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: $primaryFont;
    text-align: center;
    color: $color-spanish-gray;
    margin-top: 16px;
    margin-bottom: 7px;
  }
  .related-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .related-title {
      font-size: 28.45px;
      line-height: 38px;
      font-weight: 500;
      font-family: $primaryFont;
      color: $color-white;
    }
    .MuiIconButton-root {
      padding: 0;
    }
  }
  // RELATED CONTENT
  .related-content {
    margin-top: 18px;
    .community-details {
      display: flex;
      align-items: center;

      .community-avatar {
        width: 60px;
        height: 60px;
      }
      .community-name-date {
        margin-left: 16px;

        .community-name {
          cursor: pointer;
          font-size: 21.33px;
          line-height: 26px;
          font-family: $authHeaderFont;
          color: $color-white;
          margin-bottom: 3px;
        }
        .community-joindate {
          font-size: 16px;
          line-height: 20px;
          font-family: $authHeaderFont;
          color: $color-white;
          span {
            color: $color-blue;
          }
        }
      }
    }
  }
}
