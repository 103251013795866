@import '../../../../assets/scss/variable.scss';

.faq_banner {
  background-image: url('../../../../assets/images/banner/faq-banner.jpg');
  background-repeat: no-repeat;
  background-position: right;
  min-height: 498px;
  display: flex;
  align-items: center;
  position: relative;
  .faq_banner_overlay {
    position: absolute;
    width: 65%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(16, 16, 16, 1) 0%,
      rgba(16, 16, 16, 0) 100%
    );
  }
  .faq_container {
    z-index: 2;
    .combat-container {
      margin: 0 51px 0 92px !important;
      padding-bottom: 0 !important;

      .banner-head {
        font-family: $authHeaderFont;
        font-size: 67.42px;
      }
    }
  }
}
.faq-tab-question {
  padding: 10px 0 180px;
}
