@import '../../../../../assets/scss/variable.scss';

.additional-details {
  .additional-desc {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    color: #9d9d9d;
    margin-top: -9px;
  }
  .matching-p-txt {
    font-size: 21.33px;
    line-height: 26px;
    font-weight: 400;
    font-family: $authHeaderFont;
    margin-top: 27px;
  }

  .profile-list {
    margin-top: 9px;
    margin-bottom: 56px;
    .user-name {
      display: flex;
      align-items: center;
      svg {
        margin-left: 8px;
      }
    }
    @media (max-width: 599px) {
      margin-bottom: 36px;
    }
    .selected-pl-is {
      padding-bottom: 18px;
      margin-bottom: 18px;
      border-bottom: $blockBorder;
    }
    .opacity-0 {
      pointer-events: none;
      .MuiCheckbox-root {
        opacity: 0;
      }
    }
    .pl-i {
      display: flex;
      align-items: center;
      .MuiCheckbox-root {
        margin-right: 18px;
      }
      p {
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  //   ADDITIONAL FORM
  .additional-form {
    .additional-tle {
      font-size: 37.92px;
      line-height: 47px;
      font-weight: 400;
      font-family: $authHeaderFont;
      color: $color-white;
      margin-bottom: 18px;
      @media (max-width: 599px) {
        font-size: 30px;
        line-height: 40px;
      }
    }
    .addtext-desc {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      color: #9d9d9d;
      padding-bottom: 9px;
      margin-bottom: 18px;
    }
  }
  .details-hr {
    width: 100%;
    border-bottom: $blockBorder;
    margin: 9px 0px;
  }
  .additional-actions {
    margin-top: 6px;

    .profile-type-sbmtbtn {
      width: 49%;
      background-color: $color-white;
      font-size: 21.33px;
      line-height: 23px;
      font-family: $authHeaderFont;
      border-radius: $bRadius;
      padding-top: 15.5px;
      padding-bottom: 15.5px;
      @media (max-width: 599px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }


  .weight-range-title {
    font-size: 28.45px;
    line-height: 36px;
    font-family: $authHeaderFont;
    color: $color-white;
    padding-left: 4.5px;
    margin-bottom: 18px;
    margin-top: 40px;
  }

  .measurment-text{
    font-size: 16;
    line-height: 21px;
    font-weight: 400;
    font-family: Roboto;
    color: $color-white;

  }

  .weighclass-measurments{
    margin-left: 18px;

    .MuiTypography-root{
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      font-family: Roboto;
      color: $color-white;
      margin-left: 9px;
    }
  }
}
