@import '../../../../../assets/scss/variable.scss';

.search-field-box {
  position: relative;
  padding: 18px;
  background: $blockColor;
  border-radius: $bRadius;
  border: $blockBorder;

  .MuiOutlinedInput-inputMarginDense {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .MuiOutlinedInput-adornedStart {
    padding-left: 12.6px;
  }
  .MuiInputBase-input {
    font-size: 18px;
    line-height: 24px;
    font-family: $primaryFont;
    font-weight: 400;
    color: $color-white;
    &::placeholder {
      color: #b4babf;
    }
  }
}

.select-search-tab {
  margin: 23px 0;

  .MuiTab-root {
    @media (min-width: 600px) {
      min-width: 0;
    }
  }

  .MuiTabs-scrollable {
    min-height: 0;
  }

  .MuiTab-root {
    font-size: 18px;
    line-height: 22px;
    font-family: $authHeaderFont;
    color: #231f20;
    padding: 4px 9px;
    background-color: $color-white;
    margin-right: 9px;
    border-radius: $bRadius;
  }

  .MuiTabs-root,
  .MuiTab-root {
    @media (min-width: 600px) {
      min-height: 0;
    }
  }

  .MuiTab-textColorInherit {
    opacity: 1;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .Mui-selected {
    color: #d9e8f3;
    background-color: $color-blue;
  }
}
.tabpanel-sec{
  // background-color: $blockColor;
  // border: $blockBorder;
  padding: 0 !important;
  .result-text{
    font-family: $authHeaderFont;
    color: $color-white;
    font-size: 28.45px;
    line-height: 36px;
  }
  .usercard-skeleton {
    min-height: 200px;
    width: 100%;
    display: block !important;
  }
}
.search-box {
  
  .search-field-box {
    display: flex;
    .search-btn {
      padding: 0px 18px;
      height: 45px;
      background-color: $color-blue;
      color: $color-light-blue;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin-left: 9px;
      text-transform: unset;
      font-family: $primaryFont;
    }
  }
}
