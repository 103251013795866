@import '../../../../../assets/scss/variable.scss';

.followers-list {
  &:hover {
    box-shadow: -2px 5px 12px #e6e1e133;
  }

  width: 100%;
  position: relative;
  padding: 18px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;

  .user-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 9px 0px;
    .MuiAvatar-root {
      width: 115px;
      height: 115px;
    }
  }

  .user-name {
    margin-top: 18px;

    .name {
      font-size: 21.37px;
      line-height: 26px;
      margin-bottom: 18px;
      font-family: $authHeaderFont;
      color: $color-white;
      text-align: center;
    }
  }

  .visit-button {
    width: 100%;
    background-color: $color-blue;
    color: $color-white;
    padding: 0;
    height: 30px;
    font-size: 12px;
    line-height: 16px;
    font-family: $primaryFont;
    font-weight: 400;
    text-transform: capitalize;
    &:hover {
      background-color: $color-blue;
      color: $color-white;
    }
  }
}
