@import '../../../../../../../assets/scss/variable.scss';

.community-about-box {
  padding: 18px;
  background-color: $blockColor;
  border-radius: $bRadius;
  border: $blockBorder;

  .about-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .about-title {
      font-size: 28.45px;
      line-height: 38px;
      font-weight: 500;
      font-family: $primaryFont;
      color: $color-white;
    }
    .MuiIconButton-root {
      padding: 0;
    }
  }
  .community-about-txt {
    padding-top: 18px;
  }
}
