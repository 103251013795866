@import '../../../../../../../../../assets/scss/variable.scss';

.add-edit-descipline-box {
  position: relative;
  .discipline-dropdown-cover {
    max-height: 513px;
    width: 100%;
    background-color: #1b1b1b;
    padding-bottom: 20px;
  }

  .discipline-hr {
    margin-top: 27px;
    margin-bottom: 16px;
    height: 0px;
    border-bottom: $blockBorder;
  }

  .selected-d-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    font-family: $primaryFont;
    color: $color-white;
  }

  .MuiIconButton-root {
    padding: 0;
  }

  .discipline-partition {
    width: 100%;
    display: flex;
    justify-content: center;
    .discipline-partition-hr {
      width: 196px;
      margin-top: 13px;
      margin-bottom: 23px;
      height: 0;
      border-bottom: $blockBorder;
    }
  }
}
