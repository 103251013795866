@import 'src/assets/scss/variable.scss';

.auth-section-box {
  background-color: #000;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.5) 100%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url(../../../assets/images/banner/banner-1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  // height: 100vh;
  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  padding-top: 50px;
  @media (max-width: 599px) {
    background-image: none;
    padding-top: 0;
    z-index: 1101;
  }
  .terms-cond-text {
    display: flex;
    align-items: center;
    .MuiCheckbox-root,
    .MuiTypography-root {
      color: $color-white;
      font-size: 12px;
      font-weight: 300;
    }
  }
  .auth-grid-container {
    display: flex;
    height: 100%;
  }

  .auth-left-grid {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    text-align: center;
    padding: 30px 0;
    max-width: 455px;
    width: 100%;

    .MuiGrid-spacing-xs-3 {
      @media (max-width: 599px) {
        width: 100% !important;
        margin: 0px !important;
      }
    }
  }
  .sign-container {
    @media (max-width: 599px) {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
    .mobile-auth-logo {
      display: none;
      @media (max-width: 599px) {
        display: block;
      }
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
  .signup-button {
    padding: 8.35px 0;
    font-size: 21.33px;
    font-weight: 400;
    font-family: $authHeaderFont;
    width: 100%;
    color: $color-black-very-light;
    background: $color-white;
    border-radius: $bRadius;
    @media (max-width: 599px) {
      padding: 4.64px 0;
      font-size: 18px;
      font-weight: 500;
    }
  }
  .quote {
    margin-top: 26px;
    font-size: 21.33px;
    color: $color-white;
    text-align: center;

    @media (max-width: 599px) {
      margin-top: 10px;
      margin-bottom: 2px;
    }
  }
  .quote:before,
  .quote:after {
    background-color: $color-white;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    // width: 46.7%;
    width: 40%;
    @media (max-width: 599px) {
      width: 43%;
    }
  }
  .quote span {
    padding: 5px;
    font-family: $authHeaderFont;
    text-transform: uppercase;
  }

  // social media login
  .social-login-block {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .MuiIconButton-root {
      padding: 0;
      margin: 12px;
    }
  }

  .resend-otp-text {
    margin-top: 25px;
    position: relative;
    color: $color-white;
    font-size: 21px;
    text-decoration: underline;
    font-family: $authHeaderFont;
    cursor: pointer;
    text-align: center;
    @media (max-width: 599px) {
      font-size: 16px;
    }
  }

  .donthve-acc-btn {
    margin-top: 9px;
    padding: 14px 0px;
    font-size: 21.33px;
    line-height: 26px;
    font-weight: 400;
    font-family: $authHeaderFont;
    background-color: $color-blue;
    color: $color-white;

    &:hover {
      background-color: $color-blue;
      color: $color-white;
    }
    @media (max-width: 599px) {
      width: 93%;
    }
  }

  .fancy-link {
    display: block;
    margin: 36px 0;
    @media (max-width: 599px) {
      margin-top: 24px;
    }
    .switch_to_signin {
      background: none;
      border: none;
      color: $color-white;
      font-size: 21px;
      text-decoration: underline;
      margin-bottom: 7px;
      font-family: $authHeaderFont;
      @media (max-width: 599px) {
        font-size: 16px;
      }
    }
  }
}
.eye-icons {
  padding: 0 !important;
  position: absolute !important;
  right: 20px;
  top: 15px;
  svg {
    width: 26px;
    height: 26px;
    color: $color-white;
    g {
      fill: $color-white;
    }
  }
}
.position-relative {
  position: relative;
}
