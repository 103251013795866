@import '../../../../../../assets/scss/variable.scss';

.cobtant-cover {
  .combtant-form {
    .MuiIconButton-root {
      padding: 0;
      margin-right: 12px;
    }
    .vs-box {
      display: flex;
      padding-top: 9px;
      padding-bottom: 18px;
      .vs-txt {
        font-size: 21.33px;
        line-height: 26px;
        font-weight: 400;
        font-family: $authHeaderFont;
        color: $color-white;
        width: 100%;
        text-align: center;
      }
    }
    .slide-box {
      .slide-hr {
        margin: 14px 0px;
        border-bottom: $blockBorder;
      }
      .add-delete-comb {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      .combatant-item {
        padding: 18px 0px;

        .delc-hr {
          margin-top: 9px;
          border-bottom: $blockBorder;
        }
      }
    }
  }
}
