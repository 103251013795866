@import '../../../../assets/scss/variable.scss';

.profile-view-box {
  display: flex;
  justify-content: center;
  align-items: center;
  .user-picture {
    margin-top: 30px;
    width: 100%;
    height: 100%;
    @media (min-width: 959px) {
      width: 500px;
      height: 500px;
    }
  }
}
