@import '../../../../../../../../assets/scss/variable.scss';

.left-ad-block {
  margin-top: 9px;
  width: 100%;
  height: 174px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-blue;
  color: $color-black-very-light;
  font-size: 16px;
  line-height: 20px;
  font-family: $authHeaderFont;
  border-radius: $bRadius;
}
