@import '../../../../../../../../assets/scss/variable.scss';

.profile-type-form {
  position: relative;

  .MuiListItemIcon-root {
    min-width: 0;
    margin-right: 18px;
  }

  .type-text-block {
    display: flex;
    justify-content: space-between;
    max-width: inherit;

    @media (max-width: 599px) {
      display: block;
    }

    .label-name,
    .type-pending {
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
    }

    .label-name {
      @media (max-width: 599px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .type-pending {
      @media (max-width: 599px) {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
  .MuiTouchRipple-root {
    display: none;
  }

  .MuiIconButton-colorSecondary:hover,
  .MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: transparent;
  }

  .make-this-main-btn {
    padding: 0;
    font-size: 18px;
    line-height: 22px;
    font-family: $authHeaderFont;
    color: $color-white;
    background: transparent;

    @media (max-width: 599px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
