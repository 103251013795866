@import '../../../../../../../assets/scss/variable.scss';

.member-listing-main{
    padding: 18px;
    .member-inner{
        margin-top: 18px;
        max-height: 60vh;
        overflow-x: hidden;
        overflow-y: scroll;
        @media (max-width: 959px) {
            max-height: 36vh;
        }
    }
    ::-webkit-scrollbar {
        width: 6px;
    }
    ::-webkit-scrollbar-track {
        background-color: #231f20;
        border-radius: $bRadius;
    }
    
    ::-webkit-scrollbar-thumb {
        background: $color-blue;
        border-radius: $bRadius;
    }
}