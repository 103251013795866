@import '../../../../assets/scss/variable.scss';

.report-profile-dialog {
  .report-title-txt {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
  }

  .select-report-for {
    margin-top: 18px;
    margin-bottom: 36px;
    .MuiListItemIcon-root {
      min-width: 0;
      margin-right: 0px;
    }

    .type-text-block {
      display: flex;
      justify-content: space-between;
      max-width: inherit;

      @media (max-width: 599px) {
        display: block;
      }

      .label-name {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
      }

      .label-name {
        @media (max-width: 599px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .MuiTouchRipple-root {
      display: none;
    }

    .MuiList-padding,
    .MuiListItem-dense {
      padding-top: 0;
      padding-bottom: 0;
    }
    .MuiListItem-gutters {
      padding-left: 4px;
    }

    .MuiIconButton-colorSecondary:hover,
    .MuiCheckbox-colorSecondary.Mui-checked:hover {
      background-color: transparent;
    }
  }
}
