@import '../../../../../assets/scss/variable.scss';

.set-org-form {
  position: relative;
  color: $color-white;

  // Setup Organisation Form
  .setup-org-formcontent {
    position: relative;
  }

  .org-picture-box {
    display: flex;
    align-items: flex-end;
    margin-bottom: 22px;
    @media (max-width: 1279px) {
      // display: flex;
      justify-content: center;
    }

    @media (max-width: $mobileBreakPoint) {
      justify-content: flex-start;
    }
    .MuiIconButton-root {
      padding: 0;
      margin-right: 16px;
      width: 115px;
      height: 115px;
    }

    .MuiAvatar-root {
      width: 115px;
      height: 115px;
    }
    .camera-icon {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }

    .profile-pic-recomandations {
      position: relative;
      @media (max-width: 599px) {
        margin-top: 30px;
      }

      .rec-heding {
        font-size: 12px;
        line-height: 18px;
        font-weight: 700;
        font-family: $primaryFont;
      }
      .rec-types {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        font-family: $primaryFont;
      }
    }
  }

  .setup-org-actions {
    margin-top: 207px;
    @media (max-width: 1279px) {
      margin-top: 0;
    }
    @media (max-width: 599px) {
      margin-top: 50px;
    }
    .setup-org-sbmtbtn {
      width: 49%;
      background-color: $color-white;
      font-size: 21.33px;
      line-height: 23px;
      font-family: $authHeaderFont;
      border-radius: $bRadius;
      padding-top: 15.5px;
      padding-bottom: 15.5px;
    }
  }
}

.duplicate-org-txt {
  font-size: 18px !important;
  line-height: 22px !important;
  text-align: center;
  font-weight: 400;
  font-family: $primaryFont !important;
  color: $color-white;
  margin-bottom: 30px !important;
}
