@import '../../../../assets/scss/variable.scss';

.see-fight-record-page {
  .see-fight-record-sec {
    position: relative;
    border: $blockBorder;
    border-radius: 3px;
    padding: 18px;
    background: $blockColor;

    @media (max-width: 599px) {
      padding: 12px;
    }
    .fight-record-text {
      font-family: $authHeaderFont;
      font-size: 28.45px;
      line-height: 36px;
      color: $color-white;
      padding-bottom: 13px;
    }
    .back-to-profile {
      padding-bottom: 26px;
      .back-text {
        padding: 0;
        font-size: 21.33px;
        font-family: $authHeaderFont;
        color: $color-white;
        @media (max-width: 599px) {
          font-size: 16px;
          padding: 8px 0;
        }
      }
      @media (max-width: 599px) {
        padding-bottom: 10px;
      }
    }
  }
  .fight-record-sec {
    background-color: $color-light-black;
    border: $blockBorder;
    border-radius: 3px;
  }
}
