@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import '../scss/variable.scss';

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

// Container
.combat-container {
  margin: 36px 51px 36px 92px !important;
  padding-bottom: 36px !important;
  @media (max-width: 1279px) {
    margin: 36px 0px !important;
  }
}

// HIDE DATE ICON FOR TYPE===DATE
// input[type='date']::-webkit-calendar-picker-indicator {
//   display: none !important;
//   -webkit-appearance: none !important;
// }

// HIDE CLOCK ICON FOR INPUT===TIME
// input[type='time']::-webkit-calendar-picker-indicator {
//   display: none !important;
//   -webkit-appearance: none !important;
// }

// HIDE DATE ICON FOR INPUT=== DATETIME-LOCAL
// input[type='datetime-local']::-webkit-calendar-picker-indicator {
//   display: none !important;
//   -webkit-appearance: none !important;
// }

input[type='date'],
input[type='time'],
input[type='datetime-local'] {
  position: relative;
}
input[type='date']::-webkit-calendar-picker-indicator,
input[type='time']::-webkit-calendar-picker-indicator,
input[type='datetime-local']::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}
// ICON CHANGE FOR DATE PICKER
input[type='date']:before {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 8px;
  right: 10px;
  content: '';
  background-image: url('../images/icons/date.svg');
}
//  ICON CHNAGE FOR TIME PICKER
input[type='time']:before,
input[type='datetime-local']:before {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 12px;
  right: 10px;
  content: '';
  background-image: url('../images/icons/events/clock-icon.svg');
}
// CHANGE ICON COLOR INPUT TYPE DATE, TIME
// input[type='date']::-webkit-calendar-picker-indicator,
// input[type='time']::-webkit-calendar-picker-indicator {
//   filter: invert(1);
// }
// NUMBER ICON REMOVE
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.MuiSkeleton-root {
  background: #707070 !important;
}

.h-45 {
  height: 45px !important;
}

// show hide styling
.hide-1279 {
  @media (max-width: 1279px) {
    display: none !important;
  }
}

.hide-959 {
  @media (max-width: 959px) {
    display: none !important;
  }
}

.show-1279 {
  display: none !important;
  @media (max-width: 1279px) {
    display: inherit !important;
  }
}
.show-959 {
  display: none !important;
  @media (max-width: 959px) {
    display: inherit !important;
  }
}

.show-959-flex {
  display: none !important;
  @media (max-width: 959px) {
    display: flex !important;
  }
}

.show-breakpoint {
  display: none !important;
  @media (max-width: $mobileBreakPoint) {
    display: inherit !important;
  }
}

.show-breakpoint-flex {
  display: none !important;
  @media (max-width:$mobileBreakPoint) {
    display: flex !important;
  }
}

.hide-breakpoint {
  @media (max-width:$mobileBreakPoint) {
    display: none !important;
  }
}

// Auth Title-description
.title {
  color: $color-white;
  font-size: 67.42px !important;
  font-family: $authHeaderFont !important;
  line-height: 67.42px !important;
  @media (max-width: 959px) {
    font-size: 50px !important;
    line-height: 50px !important;
  }
  @media (max-width: 599px) {
    font-size: 40px !important;
    line-height: 40px !important;
  }
}

.admin-title {
  margin-bottom: 30px !important;
}

.desription {
  margin: 3px 0 27px 0 !important;
  color: $color-white;
  font-size: 21px !important;

  @media (max-width: 959px) {
    font-size: 18px !important;
    padding: 12px;
  }
}

.sub-desc {
  font-size: 16px !important;

  @media (max-width: 599px) {
    font-size: 14px !important;
  }
}

// Matrix TextField
.text-field-grid {
  padding-top: 4.5px !important;
  padding-bottom: 4.5px !important;
}

.date-birth-field {
  display: flex !important;
  align-items: flex-start !important;

  .date-field {
    padding-right: 4px !important;
  }

  .age-field {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 4px !important;
  }
}

.matrix-textfield {
  position: relative;
  color: $color-white;

  .MuiOutlinedInput-root {
    border-radius: $bRadius !important;
  }
  .MuiOutlinedInput-inputMarginDense {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    color: $color-white;
  }
}

.MuiAutocomplete-tag {
  height: 21px !important;
  background-color: $color-blue !important;
  color: $color-white !important;
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
  font-family: $primaryFont !important;
  position: relative !important;
  top: -1px !important;

  .MuiChip-deleteIcon {
    color: $color-white !important;
  }
}
// .notification-toast {
.Toastify__toast-container {
  .Toastify__toast-body {
    padding: 0;
  }
  .Toastify__toast {
    div {
      font-family: $authHeaderFont;
      font-size: 21.33px;
    }
    .Toastify__toast-icon {
      display: none;
    }
    .Toastify__close-button {
      opacity: 1;
      color: $color-white;
      svg {
        height: 25px;
        width: 25px;
      }
    }
  }
  @media (max-width: $mobileBreakPoint){
    left:50%;
    transform:translateX(-50%);
    bottom: 1rem;
    width: 95% !important;
  }
}
// }

.b-btn {
  background-color: $color-blue !important;
  color: #d9e8f3 !important;
  &:hover {
    background-color: $color-blue !important;
    color: #d9e8f3 !important;
  }
}

.dialog-sticky-btn{
  position: sticky;
  bottom: -10px;
  z-index: 99;
  background-color: $blockColor;
}